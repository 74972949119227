import clsx from "clsx"
import { nanoid } from "nanoid"
import {
  forwardRef,
  InputHTMLAttributes,
  LegacyRef,
  useEffect,
  useRef,
} from "react"
import { css } from "@/styles/stitches.config"
import { InputLabel } from "~/components/input-label"
import { isNotEmpty } from "~/util"

const generateStyles = css({
  "input": {
    "background": "$white",
    "border": "1px solid $gray300",
    "borderRadius": "0.1875rem",
    "color": "$baseTextColor",
    "display": "inline-block",
    "fontFamily": "$primary",
    "fontSize": "1rem",
    "height": "2.5rem",
    "margin": "0",
    "padding": "0.5rem",
    "transition": "all 250ms ease-in-out",
    "width": "100%",
    "outline": 0,
    "&::placeholder": { color: "$gray300" },
    "&[disabled],\n    &[readonly]": {
      background: "$gray200",
      cursor: "not-allowed",
    },
    "&:focus": {
      borderRadius: "0.1875rem",
      boxShadow: "0 0 0 0.0625rem $allPorts",
    },
  },
  [`input[type="text"].text-input--has-error,
  input[type="password"].text-input--has-error`]: {
    "border": "1px solid $shiraz",
    "&:focus": {
      borderRadius: "0.1875rem",
      boxShadow: "0 0 0 0.0625rem $shiraz",
    },
  },
  ".text-input--validation-error-message": {
    color: "$shiraz",
    display: "block",
    fontSize: "0.85rem",
    fontWeight: "normal",
    height: "0",
    margin: "0 0 1rem 0",
    opacity: 0,
    textTransform: "none",
    transition: "all 250ms ease-in-out",
    visibility: "hidden",
    width: "99.5%",
  },
  ".text-input--validation-error-message--is-visible": {
    height: "auto",
    margin: "0.25rem 0 0.75rem 0.1rem",
    opacity: 1,
    visibility: "visible",
  },
})

export const LegacyTextInput = forwardRef(
  (
    props: {
      type?: string
      inputId: string
      className?: string
      label?: string
      labelClassName?: string
      inputClassName?: string
      required?: boolean
      disabled?: boolean
      error?: { message: string }
    } & InputHTMLAttributes<HTMLInputElement>,
    _ref,
  ) => {
    const {
      type = "text",
      inputId,
      className,
      label,
      labelClassName,
      inputClassName,
      required = false,
      disabled = false,
      error,
      ...other
    } = props

    const ref = _ref as unknown as LegacyRef<HTMLInputElement>

    const hasError = isNotEmpty(error)

    const labelId = useRef<string | null>(null)

    useEffect(() => {
      labelId.current = nanoid()
    }, [])

    return isNotEmpty(label) ? (
      <InputLabel
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore ts thinks that InputLabel cannot have an id
        id={labelId.current}
        target={inputId}
        labelText={label}
        className={clsx(labelClassName, generateStyles().className)}
        showRequiredIndicator={required}>
        <input
          type={type}
          id={inputId}
          ref={ref}
          aria-labelledby={labelId.current ?? undefined}
          disabled={disabled}
          {...other}
          className={clsx({
            [inputClassName ?? ""]: isNotEmpty(inputClassName),
            "text-input--has-error": hasError && !disabled,
          })}
        />
        <span
          className={clsx([
            "text-input--validation-error-message",
            {
              "text-input--validation-error-message--is-visible":
                hasError && !disabled,
            },
          ])}>
          {error && error.message}
        </span>
      </InputLabel>
    ) : (
      <input
        type={type}
        id={inputId}
        className={className}
        ref={ref}
        {...other}
      />
    )
  },
)

LegacyTextInput.displayName = "TextInput"
