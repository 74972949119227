import { Button } from "@mui/material"
import { signIn } from "next-auth/react"
import { useAuth } from "@/core/hooks/use-auth"
import { TRACKING_EVENT_TYPES } from "@/core/services/aoeu/models"
import { styled } from "@/styles/stitches.config"
import { pages } from "@/urls"
import { trackClick } from "~/components/button"
import { useRedirect } from "~/hooks/use-redirect"
import { useResource } from "~/hooks/use-resource"

interface ICleverLoginButton {
  onClick?: () => void
  disabled?: boolean
  className?: string
}

export const CleverLoginButton = styled(
  function ({ onClick, disabled, className }: ICleverLoginButton) {
    const { route, redirectTo } = useRedirect()
    const { trackingApi } = useResource()
    const auth = useAuth()

    /* by default, we return the user back to their current page after a successful login
     * If the user in on the login page, we don't want to redirect them to the login page; instead,
     * if there is already a redirectTo set, they will be redirected there. Otherwise, the redirect
     * will depend on their current profile state. */
    const returnTo = route === pages.user.login ? redirectTo : route

    async function LoginWithClever() {
      trackClick(
        trackingApi,
        auth,
        "Log in with Clever",
        TRACKING_EVENT_TYPES.CLICK,
      )
      onClick?.()
      signIn(
        "auth0",
        {
          callbackUrl: returnTo
            ? `${pages.user.social}?returnTo=${returnTo}`
            : pages.user.social,
        },
        { connection: "Clever" },
      )
    }

    return (
      <Button
        startIcon={<CleverIcon />}
        variant="outlined"
        className={className}
        onClick={LoginWithClever}
        disabled={disabled}>
        Continue with Clever
      </Button>
    )
  },
  {
    "&.MuiButton-root": {
      "&, &:hover, &:focus, &:active, &:disabled": {
        border: "2px solid",
      },
      "width": "100%",
    },
  },
)

const CleverIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      viewBox="0 0 1280 1352.49">
      <path
        d="M1280,348.16C1167.69,138.18,943.07,0,675.9,0,302.22,0,0,301.88,0,675.9s302.22,675.91,675.9,676.58c267.16,0,491.78-138.17,604.1-348.16l-232.11-128.99c-77.25,134.43-202.49,219.18-371.98,219.18-228.36,0-407.04-189.57-407.04-418.27s178.68-418.27,407.04-418.27c169.49,0,294.73,84.74,371.98,219.18l232.11-128.99Z"
        fill="#1464ff"
        fillRule="evenodd"
      />
    </svg>
  )
}
