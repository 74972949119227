import * as Sentry from "@sentry/nextjs"
import HttpStatus from "http-status"
import { signIn } from "next-auth/react"
import { useTranslations } from "next-intl"
import { useEffect, useState } from "react"
import { pages } from "@/urls"
import { Alert, variants as alertType } from "~/components/alert"
import { Container } from "~/components/container"
import Link, { NextWrappedLink } from "~/components/link"
import { LoadingOverlay } from "~/components/loading-overlay"
import { NewLoginForm } from "~/components/login-form/NewLoginForm"
import { Layout } from "~/containers/layout"
import { LoginLayout } from "~/containers/LoginLayout"
import { FEATURES, TIME } from "~/enums"
import { useAuth } from "~/hooks/use-auth"
import { useLocalStorage } from "~/hooks/use-local-storage"
import { useRedirect } from "~/hooks/use-redirect"
import { useResource } from "~/hooks/use-resource"
import { useRouter } from "~/hooks/use-router"
import { MarketingCarousel } from "./MarketingCarousel"

export function Login() {
  const { redirectAfterLogin, redirectTo } = useRedirect()
  const {
    query: {
      loggedOut: _loggedOut,
      connection,
      context: marketingContext,
      unblocked,
    },
  } = useRouter()

  const t = useTranslations("containers.user.login")
  const { api } = useResource()

  const [storedEmail, setStoredEmail] = useLocalStorage("email", "")
  const defaultState = {
    loggingIn: false,
    loggedOut: !!_loggedOut,
    purchasing: !!redirectTo?.includes("purchase"),
    showEmailNotVerifiedError: false,
    showResetPasswordWarning: false,
    showInvalidCredentialsError: false,
  }
  const [{ loggingIn, loggedOut, purchasing, ...warnings }, setState] =
    useState(defaultState)

  const { features } = useAuth()

  const isLoginEnabled = features.has(FEATURES.LOGIN)
  const isLoginDisabled = !isLoginEnabled

  const enableAuth0 = features.has(FEATURES.ENABLE_AUTH0)
  const isCleverLogin = connection == "Clever"

  useEffect(() => {
    const authParams = {
      "ext-loggedOut": loggedOut,
      "ext-purchasing": purchasing,
      "ext-unblocked": unblocked || "false",
      "ext-marketingContext": marketingContext || "false",
      "prompt": "login",
    }

    if (isCleverLogin) {
      authParams.connection = "Clever"
    }

    if (enableAuth0 || isCleverLogin) {
      signIn(
        "auth0",
        {
          callbackUrl: `${pages.user.social}${
            redirectTo ? `?returnTo=${redirectTo}` : ""
          }`,
        },
        authParams,
      )
    }
  }, [])

  const login = async ({
    email: loginEmail,
    password: loginPassword,
    rememberMe: loginRememberMe,
  }) => {
    try {
      setState(() => ({ ...defaultState, loggingIn: true }))
      const response = await api.login({
        email: loginEmail,
        password: loginPassword,
        rememberMe: loginRememberMe,
      })

      const { userData } = response.data
      const { edges, firstName, id, lastName, primaryEmailId } = userData

      const email =
        (edges.emails ?? []).find(e => e.id === primaryEmailId)?.email ?? ""
      const username = `${firstName} ${lastName}`.trim()

      if (email && id && username) {
        Sentry.setUser({
          id,
          email,
          username,
        })
      }

      // update the remembered email on successful login
      setStoredEmail(loginRememberMe ? loginEmail : "")
      await redirectAfterLogin()
    } catch (error) {
      if (error.name === "FeatureToggleError") {
        return
      }
      setState(_state => {
        const showEmailNotVerifiedError = (error.message || "")
          .toLowerCase()
          .includes("email has not been verified")
        const showResetPasswordWarning =
          error.status === HttpStatus.PRECONDITION_REQUIRED
        return {
          ..._state,
          loggingIn: false,
          showEmailNotVerifiedError,
          showResetPasswordWarning,
          showInvalidCredentialsError:
            !showEmailNotVerifiedError && !showResetPasswordWarning,
        }
      })
    }
  }

  const loginFormProps = {
    loggingIn,
    email: storedEmail,
    rememberMe: !!storedEmail,
    loginDisabled: isLoginDisabled,
    onSubmit: login,
  }

  const loggedOutNotice = loggedOut && (
    <Alert
      type={alertType.info}
      show={loggedOut}
      autoHideMS={TIME.TWENTY_SECONDS}
      title={t("loggedOutNoticeHeading")}
      message={t("loggedOutNoticeMessage")}
    />
  )

  const hasAlerts =
    warnings.showEmailNotVerifiedError ||
    warnings.showResetPasswordWarning ||
    warnings.showInvalidCredentialsError ||
    isLoginDisabled

  const alerts = (
    <>
      <Alert
        type={alertType.error}
        show={warnings.showEmailNotVerifiedError}
        title={t("emailNotVerifiedNoticeHeading")}
        message={t("emailNotVerifiedNoticeMessage", {
          link: (
            <NextWrappedLink
              href={pages.user.resendVerifyEmail}
              key="resend-verify-email-link">
              {t("emailNotVerifiedNoticeLinkText")}
            </NextWrappedLink>
          ),
        })}
      />
      <Alert
        type={alertType.warning}
        show={warnings.showResetPasswordWarning}
        title={t("accountRequiresPasswordResetNoticeHeading")}
        message={t("accountRequiresPasswordResetNoticeMessage", {
          helpMailToLink: (
            <Link
              key="password-reset-help-email-link"
              href={`mailto:help@theartofeducation.edu?subject=${t(
                "accountRequiresPasswordResetNoticeHelpEmailSubject",
              )}`}>
              help@theartofeducation.edu
            </Link>
          ),
        })}
      />
      <Alert
        type={alertType.error}
        show={warnings.showInvalidCredentialsError}
        title={t("invalidCredentialsNoticeHeading")}
        message={t("invalidCredentialsNoticeMessage", {
          link: (
            <NextWrappedLink
              href={pages.user.forgotPassword}
              key="reset-password-link">
              {t("invalidCredentialsNoticeLinkText")}
            </NextWrappedLink>
          ),
        })}
      />
      <Alert
        type={alertType.warning}
        show={isLoginDisabled}
        title={t("loginDisabledNoticeHeading")}
        message={t("loginDisabledNoticeMessage")}
      />
    </>
  )

  if (enableAuth0 || isCleverLogin) {
    return (
      <Layout>
        <Container>
          <LoadingOverlay show={true} showLabel={false} />
        </Container>
      </Layout>
    )
  }

  return (
    <LoginLayout leftColumn={<MarketingCarousel />}>
      {loggedOutNotice}
      <NewLoginForm alerts={hasAlerts && alerts} {...loginFormProps} />
    </LoginLayout>
  )
}
