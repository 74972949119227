import { useEffect, useRef, useState } from "react"

/**
 * After `timeout` milliseconds, transition from `state` to `inactiveState`. The `onTimeout`
 * callback is executed during this transition. Returns the currently active internal state.
 * If `timeout` is 0, null, or undefined, `state` is always returned, no state transition
 * will occur, and onTimeout will not fire.
 *
 * If passed `state` or `inactiveState` are changed on subsequent render, the return value will
 * transition back to `state` and the timer will start over.
 *
 * If passed `timout`, `onTimeout` are changed in a subsequent render, the timer will start over.
 * This includes, e.g, a function defined within the component.
 *
 * @param timeout milliseconds before state transition
 * @param onTimeout callback to execute during state transition
 * @param state starting internal state (on mount and before the timer ends)
 * @param inactiveState destination state after timer ends
 * @returns {state} returns the current internal state of the timer
 */

export const MAX_SAFE_TIMEOUT = Math.pow(2, 32 - 1) - 1 // 32-bit signed int max

export const useStatefulTimeout = <T>(
  timeout: number,
  onTimeout: ((activatedState: T) => void) | null,
  state: T,
  inactiveState: T,
): T => {
  const [timedState, setTimedState] = useState<T>(state)
  /* If the state or inactiveState parameters passed from the parent change,
  the internal state of this component is reset. */
  useEffect(() => setTimedState(() => state), [state, inactiveState])

  const timerRef = useRef(setTimeout(() => {}, MAX_SAFE_TIMEOUT))
  useEffect(() => {
    if (timeout) {
      timerRef.current = setTimeout(() => {
        // set internal state (and externally visible computed state)
        // to the indicated inactiveState
        setTimedState(inactiveState)
        // execute callback
        onTimeout && onTimeout(inactiveState)
      }, timeout)
    }
    // clear timer on state change or unmount, which will prevent the
    // setTimeout callback from executing
    return () => {
      timerRef?.current && clearTimeout(timerRef.current)
    }
    /* We intentionally omit timerRef as a dependency, since the desired side effect of this
     * callback is to update timerRef */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout, onTimeout, state, inactiveState])
  return timedState
}
