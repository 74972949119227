import { colors, styled } from "@/styles/stitches.config"

const Separator = styled("div", {
  display: "grid",
  gridTemplateAreas: `"separator"`,
  gridTemplateColumns: "1fr",
  gridTemplateRows: "1fr",
})

const Rule = styled("hr", {
  border: "0",
  borderBottom: "1px solid $gray300",
  gridArea: "separator",
  height: "1px",
  margin: "0.5rem 0",
  width: "100%",
})

const Label = styled("span", {
  background: "$white",
  gridArea: "separator",
  margin: "0 auto",
  padding: "0 0.5rem",
})

interface ILabeledRuleLine {
  labelBackgroundColor?: string
  label: string
}

export function LabeledRuleLine({
  labelBackgroundColor = colors.white,
  label,
  ...props
}: ILabeledRuleLine): JSX.Element {
  return (
    <Separator {...props}>
      <Rule />
      <Label style={{ background: labelBackgroundColor }}>{label}</Label>
    </Separator>
  )
}
