import { styled } from "@/styles/stitches.config"
import { SvgIcons } from "../components/svg-icons"

interface ILoginLayout {
  children: React.ReactNode
  leftColumn?: React.ReactNode
}

const GridContainer = styled("div", {
  "display": "grid",
  "@initial": {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr auto",
  },
  "@l": {
    height: "100vh",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr",
  },
  "@xl": {
    height: "100vh",
    gridTemplateColumns: "1fr 2fr",
    gridTemplateRows: "1fr",
  },
})

const LeftColumn = styled("div", {
  "position": "relative",
  "@initial": {
    gridRow: 2,
  },
  "@l": {
    gridRow: "unset",
  },
})

const RightColumn = styled("div", {
  "background": "$white",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "@initial": {
    gridRow: 1,
  },
  "@l": {
    background: "$gray100",
    gridRow: "unset",
  },
})

const FormContainer = styled("div", {
  maxWidth: "500px",
  width: "100%",
})

export function LoginLayout({
  children,
  leftColumn,
}: ILoginLayout): React.ReactNode {
  return (
    <>
      <SvgIcons />
      <GridContainer data-testid="new-login-page">
        <LeftColumn>{leftColumn}</LeftColumn>
        <RightColumn>
          <FormContainer>{children}</FormContainer>
        </RightColumn>
      </GridContainer>
    </>
  )
}
