/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Typography } from "@mui/material"
import { useTranslations } from "next-intl"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { styled } from "@/styles/stitches.config"
import { pages } from "@/urls"
import { Button } from "~/components/button"
import { Checkbox } from "~/components/checkbox"
import { CleverLoginButton } from "~/components/CleverLoginButton"
import { GoogleAuthButton } from "~/components/google-auth-button/NewGoogleAuthButton"
import { LabeledRuleLine } from "~/components/LabeledRuleLine"
import { LegacyTextInput } from "~/components/legacy-text-input"
import { NextWrappedLink } from "~/components/link"
import { PasswordInput } from "~/components/password-input"
import { AOEULogo } from "~/components/svg-logos/AOEULogo"
import { isNotEmpty } from "~/util"
import { getLoginValidators } from "~/validators/login"

type TCredentials = { email: string; password: string; rememberMe: boolean }

interface INewLoginForm {
  loggingIn: boolean
  email: string
  rememberMe: boolean
  onSubmit: (credentials: TCredentials) => void
  loginDisabled: boolean
  className?: string
  alerts: JSX.Element
}

function NewLoginFormComponent({
  loggingIn,
  email,
  rememberMe,
  onSubmit,
  loginDisabled,
  className,
  alerts,
}: INewLoginForm) {
  const t = useTranslations("components.loginForm")
  const validators = getLoginValidators(t)

  const [loggingInWithGoogle, setLoggingInWithGoogle] = useState(false)
  const [loggingInWithClever, setLoggingInWithClever] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors: validationErrors },
  } = useForm({
    defaultValues: {
      email,
      password: "",
      rememberMe,
    },
    mode: "onTouched",
    reValidateMode: "onChange",
  })

  const fields = {
    email: register("email", {
      validate: value => {
        const { error } = validators.email.validate(value)

        return isNotEmpty(error) ? error?.message : true
      },
    }),
    password: register("password", {
      validate: value => {
        const { error } = validators.password.validate(value)

        return isNotEmpty(error) ? error?.message : true
      },
    }),
  }

  const loginWithGoogle = () => {
    setLoggingInWithGoogle(true)
  }

  const loginWithClever = () => {
    setLoggingInWithClever(true)
  }

  const formSubmit = (credentials: TCredentials) => {
    onSubmit(credentials)
  }

  const disabled =
    loggingIn || loginDisabled || loggingInWithGoogle || loggingInWithClever

  return (
    <div className={className}>
      {alerts && <div className="alerts-container">{alerts}</div>}
      <div className="logo-container">
        <AOEULogo />
      </div>
      <form
        id="login"
        className="form--login"
        onSubmit={handleSubmit(async credentials => formSubmit(credentials))}>
        <div>
          <div className="form--login--social-login-options">
            <GoogleAuthButton onClick={loginWithGoogle} disabled={disabled} />
            <CleverLoginButton onClick={loginWithClever} disabled={disabled} />
          </div>
          <StyledLabeledRuleLine label="or login using email" />
          <LegacyTextInput
            inputId="email"
            // @ts-ignore
            name="email"
            className="form--login--email-address-field"
            label={t("emailAddressFieldLabel")}
            // @ts-ignore
            error={validationErrors.email}
            required={true}
            readOnly={disabled}
            disabled={disabled}
            {...fields.email}
            data-testid="email"
          />
          <PasswordInput
            // @ts-ignore
            inputId="password"
            // @ts-ignore
            name="password"
            className="form--login--password-field"
            label={t("passwordFieldLabel")}
            error={validationErrors.password}
            readOnly={disabled}
            disabled={disabled}
            {...fields.password}
            data-testid="password"
          />
          <Checkbox
            // @ts-ignore
            inputId="rememberMe"
            label={t("rememberMeOptionLabel")}
            checked={rememberMe}
            disabled={disabled}
            {...register("rememberMe")}
          />
          {!loginDisabled ? (
            <p>
              <NextWrappedLink href={pages.user.forgotPassword}>
                {t("forgotPasswordLinkText")}
              </NextWrappedLink>
            </p>
          ) : null}
          <footer>
            <Button
              aria-label="login-button"
              fullWidth
              disableElevation
              eventTitle={t("loginButtonLabel")}
              type="submit"
              disabled={disabled}>
              {t("loginButtonLabel")}
            </Button>
            <Typography variant="body2">
              <strong>
                Don&apos;t have an account?&nbsp;
                <NextWrappedLink href={pages.user.create}>
                  Sign up
                </NextWrappedLink>
              </strong>
            </Typography>
          </footer>
        </div>
      </form>
    </div>
  )
}

export const NewLoginForm = styled(NewLoginFormComponent, {
  "background": "$white",
  "padding": "2rem",
  ".alerts-container": {
    marginBottom: "1rem",
  },
  ".logo-container": {
    color: "$aoeuBlue",
    maxWidth: "300px",
    margin: "0 auto 1rem auto",
  },
  "h1": {
    fontFamily: "$secondary",
    fontSize: "1.5rem",
    fontWeight: 300,
    lineHeight: "1.75rem",
    margin: "0 0 2rem 0",
    textAlign: "center",
  },
  "form": {
    "display": "block",
    "margin": "0",
    "width": "auto",
    "borderRadius": "3px",
    "a": { textDecoration: "none" },
    "footer": {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      padding: "2rem 0 0 0",
      alignItems: "center",
    },
    ".form--login--social-login-options": {
      padding: "0",
      button: {
        marginTop: "0.5rem",
      },
    },
  },
})

const StyledLabeledRuleLine = styled(LabeledRuleLine, {
  margin: "$4 0",
})
