import { Box, BoxProps } from "@mui/material"
import { AnimatePresence, motion, Variants } from "framer-motion"
import React, { useEffect, useRef } from "react"
import { styled } from "@/styles/stitches.config"

const offsetPx = 200
const transitionDuration = 0.25
const exitCurve = [0.64, 0.01, 0.78, 0.52] // demo: https://cubic-bezier.com/#.64,.01,.78,.52
const enterCurve = [0.02, 0.29, 0.04, 0.99] // https://cubic-bezier.com/#.02,.29,.04,.99

const variants: Variants = {
  exit: props => {
    const axis = props?.direction === "horizontal" ? "x" : "y"
    return {
      [axis]: props?.isForward ? -offsetPx : offsetPx,
      opacity: 0,
      transition: {
        type: "tween",
        ease: exitCurve,
        duration: transitionDuration,
      },
    }
  },
}

type TDirection = "horizontal" | "vertical"

export interface ICarousel extends BoxProps {
  children: React.ReactNode
  pane: number
  onSetPane?: (paneNumber: number) => void
  direction?: TDirection
  cycle?: boolean
  withNavigation?: boolean
  renderNavigationItem?: (
    isActive: boolean,
    onClick: () => void,
  ) => React.ReactNode
}

export function Carousel({
  pane,
  children,
  direction = "horizontal",
  cycle = false,
  onSetPane,
  withNavigation,
  renderNavigationItem,
  ...rest
}: ICarousel): JSX.Element {
  const prevPaneRef = useRef<number>(pane)
  const cycleTimeoutRef = useRef<NodeJS.Timer | null>(null)
  const hasNavigatedRef = useRef<boolean>(false)

  useEffect(() => {
    prevPaneRef.current = pane
  }, [pane])

  useEffect(() => {
    if (!cycle || hasNavigatedRef.current) {
      return
    }

    // Start the timeout
    const startTimeout = () => {
      const timeoutId = setTimeout(() => {
        const nextPane =
          pane === React.Children.count(children) - 1 ? 0 : pane + 1
        onSetPane?.(nextPane)
      }, 5000)
      cycleTimeoutRef.current = timeoutId
    }

    // Stop the timeout
    const stopTimeout = () => {
      const timeoutId = cycleTimeoutRef.current
      timeoutId && clearTimeout(timeoutId)
      cycleTimeoutRef.current = null
    }

    if (!cycleTimeoutRef.current) {
      startTimeout()
    }

    // Event listeners for tab focus and blur
    const handleFocus = () => {
      if (!cycleTimeoutRef.current) {
        startTimeout()
      }
    }

    const handleBlur = () => {
      stopTimeout()
    }

    window.addEventListener("focus", handleFocus)
    window.addEventListener("blur", handleBlur)

    // Clean up the event listeners when component unmounts
    return () => {
      stopTimeout()
      window.removeEventListener("focus", handleFocus)
      window.removeEventListener("blur", handleBlur)
    }
  }, [cycle, pane, onSetPane])

  const isForward = cycle || pane > prevPaneRef.current

  return (
    <Box position="relative">
      <Box display="grid" width="100%" {...rest}>
        <AnimatePresence initial={false} custom={{ isForward, direction }}>
          {React.Children.map(children, (child, i) => {
            const isActive = pane === i
            if (!isActive) {
              return null
            }

            const axis = direction === "horizontal" ? "x" : "y"

            return (
              <motion.div
                initial={{
                  [axis]: isForward ? offsetPx : -offsetPx,
                  opacity: 0,
                }}
                variants={variants}
                animate={{
                  x: 0,
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: "tween",
                    ease: enterCurve,
                    duration: transitionDuration * 1.5,
                    delay: transitionDuration / 1.5,
                  },
                }}
                exit={"exit"}
                style={{
                  gridArea: "1 / 1 / 2 / 2",
                  position: "relative",
                }}>
                {child}
              </motion.div>
            )
          })}
        </AnimatePresence>
      </Box>
      {(withNavigation || renderNavigationItem) && (
        <Box sx={{ ...getNavigationContainerStyles(direction) }}>
          {React.Children.map(children, (_, i) => {
            const isActive = pane === i
            const onClick = () => {
              hasNavigatedRef.current = true
              onSetPane?.(i)
            }

            if (renderNavigationItem) {
              return renderNavigationItem(isActive, onClick)
            }

            return (
              <DotButton
                data-testid={`carousel-navigation-${i}`}
                key={i}
                isActive={isActive}
                onClick={onClick}
                aria-label={`Go to Slide ${i + 1}`}
              />
            )
          })}
        </Box>
      )}
    </Box>
  )
}

const DotButton = styled("button", {
  height: "12px",
  width: "12px",
  borderRadius: "50%",
  padding: 0,
  border: "none",
  backgroundColor: "$gray300",
  cursor: "pointer",
  variants: {
    isActive: {
      true: {
        backgroundColor: "$aoeuBlue2",
      },
    },
  },
})

function getNavigationContainerStyles(direction: TDirection) {
  const baseStyles = {
    position: "absolute",
    display: "flex",
    gap: "16px",
  }
  if (direction === "horizontal") {
    return {
      ...baseStyles,
      flexDirection: "row",
      bottom: "16px",
      left: "50%",
      transform: "translateX(-50%)",
    }
  }
  return {
    ...baseStyles,
    flexDirection: "column",
    right: "16px",
    top: "50%",
    transform: "translateY(-50%)",
  }
}
