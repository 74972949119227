import { useState, useEffect, useReducer } from "react"
export const KEY_PREFIX = "aoeu:data"

/* eslint-disable @typescript-eslint/no-explicit-any */

const get = <T>(key: string, defaultValue: T): T => {
  try {
    const saved = localStorage.getItem(key)
    if (saved) return JSON.parse(saved)
  } catch {
    // pass
  }
  return defaultValue
}

export const storageKey = (key: string): string => {
  return `${KEY_PREFIX}:${key}`
}

/*
 * Same interface as useState but with a key.
 *
 * Example:
 *  [value, setValue] = useLocalStorage('path.of.key', defaultValue)
 * */
export const useLocalStorage = <T extends JSONValue>(
  key: string,
  defaultValue: T,
): [T, (v: T) => void] => {
  const _key = storageKey(key)
  const [value, setValue] = useState<T>(() => get(_key, defaultValue))

  useEffect(() => {
    try {
      localStorage.setItem(_key, JSON.stringify(value))
    } catch {
      // pass
    }
  }, [_key, value])

  return [value, setValue]
}

/*
 * Nearly identical interface to useReducer with two differences:
 * - must provide a localStorage key as the first argument
 * - the third argument for useReducer (initializer) is not available, which greatly
 *   simplifies the type description
 *
 * Example:
 *  [value, dispatch] = useLocalStorageReducer('path.of.key', reducer, defaultValue)
 * */
export const useLocalStorageReducer = <
  T extends JSONValue,
  R extends (prevState: T, action: any) => T,
>(
  key: string,
  reducer: R,
  defaultValue: T,
): [T, (action: any) => void] => {
  const _key = storageKey(key)
  /* Types for useReducer are heavily overloaded and fairly complex. This type definition should
  be valid, IMO, but I can't make it match the interface overloads. Regardless, I assert that the
  type interface we expose for this hook is compatible with the useReducer interface overloads
  and that typescript complaints can be safely ignored. */
  const [value, dispatch] = <[T, (action: any) => void]>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useReducer<R, T>(reducer, get(_key, defaultValue))
  )

  useEffect(() => {
    try {
      localStorage.setItem(_key, JSON.stringify(value))
    } catch {
      // pass
    }
  }, [_key, value])
  return [value, dispatch]
}
