import { AnimatePresence, motion } from "framer-motion"
import { ReactElement, ReactNode } from "react"
import { styled } from "@/styles/stitches.config"
import { SvgIcon } from "~/components/svg-icons"
import { useStatefulTimeout } from "~/hooks/use-stateful-timeout"

export const variants = {
  error: "error",
  info: "info",
  question: "question",
  success: "success",
  warning: "warning",
} as const

const iconSize = "1.75rem"
const Icon = styled(SvgIcon, {
  color: "$white",
  height: iconSize,
  width: iconSize,
})

const IconBox = styled(motion.div, {
  alignItems: "center",
  alignSelf: "stretch",
  display: "flex",
  justifyContent: "center",
  padding: "$3 $4",

  variants: {
    type: {
      [variants.error]: {
        backgroundColor: "$red",
      },
      [variants.info]: {
        backgroundColor: "$stroke",
      },
      [variants.question]: {
        backgroundColor: "$stroke",
      },
      [variants.success]: {
        backgroundColor: "$lightGreen",
      },
      [variants.warning]: {
        backgroundColor: "$stroke",
      },
    },
  },
})

const MessageBox = styled(motion.div, {
  padding: "$3",
})

const Title = styled("div", {
  color: "$mainText",
  fontSize: "1rem",
  fontWeight: "bold",
  lineHeight: "1.25rem",
})

const Message = styled("p", {
  color: "$mainText",
  fontSize: "0.8125rem",
  lineHeight: "1.0625rem",
  margin: "0",
})

const AlertBox = styled(motion.div, {
  alignItems: "center",
  background: "$white",
  borderRadius: "0.375rem",
  display: "flex",
  flexDirection: "row",
  gap: "$1",
  justifyContent: "start",
  transformOrigin: "top center",
  wrap: "noWrap",

  variants: {
    type: {
      [variants.error]: {
        border: "1px solid $red",
      },
      [variants.info]: {
        border: "1px solid $stroke",
      },
      [variants.question]: {
        border: "1px solid $stroke",
      },
      [variants.success]: {
        border: "1px solid $lightGreen",
      },
      [variants.warning]: {
        border: "1px solid $stroke",
      },
    },
  },
})

const alertAnimation = {
  initial: {
    opacity: 0,
    transform: "scaleY(0)",
  },
  animate: {
    opacity: 1,
    transform: "scaleY(1)",
  },
  exit: {
    height: 0,
    opacity: 0,
    transform: "scaleY(0)",
  },
  transition: {
    duration: 0.5,
    ease: "easeInOut",
  },
}

const contentAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    duration: 0.25,
    ease: "easeInOut",
  },
}

export type AlertProps = {
  type?: typeof variants[keyof typeof variants]
  show?: boolean
  title: string
  message?: ReactNode
  autoHideMS?: number
  onHide?: () => void
  className?: string
}
export const Alert = ({
  type = variants.info,
  show = false,
  title,
  message,
  autoHideMS = 0,
  onHide = () => {},
  className,
  ...props
}: AlertProps): ReactElement => {
  const alertProps = {
    type,
    gap: "1",
    ...alertAnimation,
    className,
    ...props,
  }

  const shown = useStatefulTimeout(autoHideMS, onHide, show, false)
  const _shown = autoHideMS ? shown : show

  return (
    <AnimatePresence>
      {_shown ? (
        <AlertBox {...alertProps}>
          <IconBox type={type} {...contentAnimation}>
            <Icon symbolId={`alert-icon-${type}`} />
          </IconBox>
          <MessageBox {...contentAnimation}>
            <Title>{title}</Title>
            <Message>{message}</Message>
          </MessageBox>
        </AlertBox>
      ) : null}
    </AnimatePresence>
  )
}
