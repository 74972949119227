import Joi from "joi"
import { InputValidationError } from "~/errors/input-validation-error"

export function getLoginValidators(t) {
  const email = Joi.string()
    .email({
      tlds: false,
    })
    .trim()
    .required()
    .error(() => new InputValidationError(t("validation.email")))

  const password = Joi.string()
    .required()
    .error(() => new InputValidationError(t("validation.password")))

  return {
    email,
    password,
  }
}
