import clsx from "clsx"
import { useTranslations } from "next-intl"
import { styled } from "@/styles/stitches.config"
import { isNotEmpty } from "~/util"
import type { ReactNode } from "react"

export const InputLabel = styled(
  function ({
    children,
    labelText,
    labelTextClassName,
    target,
    className,
    showRequiredIndicator = false,
    ...attributes
  }: {
    children?: ReactNode
    labelText?: string
    labelTextClassName?: string
    className?: string
    target?: string
    showRequiredIndicator?: boolean
  }) {
    const t = useTranslations("components.inputLabel")

    const labelClasses = clsx("input-label--text", labelTextClassName, {
      ["input-label--has-target"]: isNotEmpty(target),
    })

    const renderLabelText = () => {
      if (isNotEmpty(labelText)) {
        return (
          <span className={labelClasses}>
            {labelText}
            {showRequiredIndicator && (
              <span
                title={t("requiredIndicatorTitle") as string}
                className="input-label--required-indicator">
                *
              </span>
            )}
          </span>
        )
      }

      return null
    }

    return (
      <label
        className={className}
        htmlFor={target ?? undefined}
        {...attributes}>
        {renderLabelText()}
        {children}
      </label>
    )
  },
  {
    "color": "$oracle",
    "cursor": "default",
    "display": "block",
    "fontSize": "1rem",
    "fontWeight": 500,
    "width": "100%",
    ".input-label--text": {
      alignItems: "center",
      display: "flex",
      margin: "0 0 0.25rem 0.1rem",
    },
    ".input-label--has-target": { cursor: "pointer" },
    ".input-label--required-indicator": {
      color: "$shiraz",
      display: "inline-block",
      fontSize: "1.25rem",
      lineHeight: "0.75rem",
      margin: "0 0 -0.5rem 0.25rem",
    },
  },
)
