import {
  Box,
  Typography,
  List,
  ListItem,
  SvgIcon,
  SvgIconProps,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Carousel } from "@/core/components/new-carousel/Carousel"
import { styled } from "@/styles/stitches.config"
import Link from "~/components/link"

const content = [
  {
    LogoClass: FlexLogo,
    color: "#007DB6",
    heading: "1000s of Premium Curriculum Materials.",
    items: ["Lesson Plans", "Resources", "Assessments", "Videos", "Artist Bios"],
    link: "https://theartofeducation.edu/flex/?utm_campaign=FLEX%20Evergreen&utm_source=login&utm_medium=carousel-flex",
  },
  {
    LogoClass: ProLogo,
    color: "#F16457",
    heading: "The only on-demand PD platform designed for K–12 art teachers.",
    items: [
      "Relevant, Personalized PD",
      "Video Tutorials",
      "Essential Resources",
      "Unlimited PD Hours",
    ],
    link: "https://theartofeducation.edu/pro?utm_campaign=PRO%20Evergreen&utm_source=login&utm_medium=carousel-pro",
  },
  {
    LogoClass: MastersLogo,
    color: "#007DB6",
    heading:
      "An online Master of Arts in Art Education degree designed by art teachers.",
    items: [
      "Ridiculously Relevant Coursework",
      "Designed for Working Art Educators",
      "Top Art Education Faculty",
    ],
    link: "https://theartofeducation.edu/masters/?utm_campaign=Master%27s%20Evergreen&utm_source=login&utm_medium=carousel-ma",
  },
  {
    LogoClass: GraduateLogo,
    color: "#485DAA",
    heading:
      "Ridiculously relevant online courses designed for art teachers by art teachers.",
    items: [
      "Perfect for License Renewal and Salary Advancement",
      "30+ Engaging Courses",
      "Earn 500-Level Graduate Credits",
    ],
    link: "https://theartofeducation.edu/courses/?utm_campaign=Grad%20Course%20Evergreen&utm_source=login&utm_medium=carousel-courses",
  },
  {
    LogoClass: NowLogo,
    color: "#D666A7",
    heading: "The world’s largest online conference for K-12 art teachers.",
    items: [
      "Multi-Day Virtual Event",
      "30+ Art Education Presentations",
      "Connects Thousands of Art Teachers Worldwide",
      "A Full Year of On-Demand Access with the After Pass",
    ],
    link: "https://theartofeducation.edu/now/?utm_campaign=NOW%20Evergreen&utm_source=login&utm_medium=carousel-now",
  },
]

export function MarketingCarousel(): React.ReactNode {
  const [pane, setPane] = useState<number | null>(null)
  const onSetPane = (paneNumber: number) => {
    setPane(paneNumber)
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const contextParam = queryParams.get("context")

    if (contextParam === "flex") {
      setPane(0)
    } else if (contextParam === "pro") {
      setPane(1)
    } else {
      setPane(Math.floor(Math.random() * content.length))
    }
  }, [])

  if (pane === null) {
    return null
  }

  return (
    <Carousel
      cycle
      direction="vertical"
      withNavigation
      pane={pane}
      onSetPane={onSetPane}
      height="100vh"
      overflow="hidden">
      {content.map(({ LogoClass, heading, items, link, color }, i) => (
        <MarketingPane key={i}>
          <MarketingPaneContent>
            <LogoClass />
            <Typography variant="h5" margin="32px 0">
              <strong>{heading}</strong>
            </Typography>
            <List>
              {items.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    paddingLeft: 0,
                  }}>
                  <CheckIcon
                    fill={color}
                    fontSize="medium"
                    sx={{ transform: "translateY(6px)" }}
                  />
                  <Typography variant="body1">{item}</Typography>
                </ListItem>
              ))}
            </List>
            <StyledLink href={link}>
              <Typography
                variant="body2"
                paddingBottom="4px"
                textTransform="uppercase"
                display="flex"
                alignItems="center"
                gap="8px">
                <strong>Learn more</strong>
                <RightArrowIcon sx={{ transform: "translateY(4px)" }} />
              </Typography>
            </StyledLink>
          </MarketingPaneContent>
        </MarketingPane>
      ))}
    </Carousel>
  )
}

const MarketingPane = styled(Box, {
  "position": "absolute",
  "left": 0,
  "top": 0,
  "width": "100%",
  "height": "100%",
  "display": "flex",
  "justifyContent": "center",
  "padding": "2rem",
  "@initial": {
    alignItems: "flex-start",
  },
  "@l": {
    alignItems: "center",
  },
})

const MarketingPaneContent = styled(Box, {
  maxWidth: "414px",
})

const StyledLink = styled(Link, {
  textDecoration: "underline !important",
})

const CheckIcon = ({ fill, ...rest }: SvgIconProps) => {
  return (
    <SvgIcon {...rest}>
      <path
        d="M5 12.2866L0 7.28662L1.75 5.53662L5 8.78662L13.25 0.536621L15 2.28662L5 12.2866Z"
        fill={fill}
      />
    </SvgIcon>
  )
}

const RightArrowIcon = ({ fill, ...rest }: SvgIconProps) => {
  return (
    <SvgIcon {...rest}>
      <path
        d="M10 12.1436L8.6 10.6936L12.15 7.14355H0V5.14355H12.15L8.6 1.59355L10 0.143555L16 6.14355L10 12.1436Z"
        fill={fill}
      />
    </SvgIcon>
  )
}

function FlexLogo() {
  return (
    <svg
      width="350"
      height="66"
      viewBox="0 0 350 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_140_3339)">
        <path
          d="M86.5877 22.1537H101.944V24.3829H88.998V32.0147H100.57V34.212H88.998V43.5228H86.5877V22.1537Z"
          fill="#395056"
        />
        <path
          d="M107.467 22.1537H109.881V41.2936H121.907V43.5228H107.467V22.1537Z"
          fill="#395056"
        />
        <path
          d="M127.097 22.1537H142.545V24.3509H129.507V31.6455H141.171V33.8464H129.507V41.3256H142.698V43.5228H127.097V22.1537Z"
          fill="#395056"
        />
        <path
          d="M165.806 22.1537L157.929 32.6253L166.126 43.5228H163.258L156.417 34.2936L149.581 43.5228H146.801L154.983 32.6253L147.106 22.1537H149.946L156.478 30.9462L163.013 22.1537H165.806Z"
          fill="#395056"
        />
        <path
          d="M180.459 32.8986V32.8382C180.459 26.8038 184.975 21.788 191.265 21.788C195.141 21.788 197.462 23.1618 199.599 25.1744L197.949 26.9493C196.149 25.2419 194.133 24.0208 191.233 24.0208C186.505 24.0208 182.962 27.8651 182.962 32.7814V32.8418C182.962 37.7865 186.533 41.6628 191.233 41.6628C194.165 41.6628 196.089 40.534 198.133 38.5816L199.72 40.1364C197.491 42.3976 195.049 43.892 191.172 43.892C185.007 43.8884 180.459 39.0467 180.459 32.8986Z"
          fill="#395056"
        />
        <path
          d="M204.271 37.538V27.7409H206.621V36.9701C206.621 39.8986 208.207 41.7302 210.987 41.7302C213.67 41.7302 215.719 39.7459 215.719 36.7855V27.7409H218.037V43.5228H215.719V40.7754C214.654 42.4828 213.031 43.8565 210.312 43.8565C206.496 43.8565 204.271 41.2936 204.271 37.538Z"
          fill="#395056"
        />
        <path
          d="M224.174 27.7409H226.524V31.8621C227.685 29.2353 229.974 27.3433 232.967 27.464V29.9985H232.782C229.332 29.9985 226.524 32.4833 226.524 37.2328V43.5228H224.174V27.7409Z"
          fill="#395056"
        />
        <path
          d="M237.393 27.7409H239.743V31.8621C240.904 29.2353 243.193 27.3433 246.182 27.464V29.9985H245.994C242.544 29.9985 239.736 32.4833 239.736 37.2328V43.5228H237.386L237.393 27.7409Z"
          fill="#395056"
        />
        <path
          d="M250.672 21.6957H253.356V24.2906H250.672V21.6957ZM250.825 27.7302H253.175V43.5122H250.825V27.7302Z"
          fill="#395056"
        />
        <path
          d="M258.517 35.7064V35.6461C258.503 34.5726 258.702 33.5069 259.101 32.5105C259.501 31.5141 260.094 30.6067 260.846 29.8405C261.598 29.0743 262.494 28.4645 263.483 28.0463C264.472 27.6281 265.533 27.4097 266.607 27.4037C269.596 27.4037 271.459 28.6567 272.925 30.2115L271.367 31.8621C270.118 30.5487 268.712 29.4802 266.575 29.4802C263.38 29.4802 260.928 32.1957 260.928 35.5857V35.6461C260.928 39.0644 263.412 41.7835 266.728 41.7835C268.744 41.7835 270.299 40.7754 271.552 39.43L273.046 40.8499C271.459 42.6248 269.567 43.9026 266.607 43.9026C265.536 43.9008 264.476 43.6868 263.489 43.273C262.501 42.8591 261.605 42.2536 260.853 41.4915C260.101 40.7293 259.507 39.8257 259.106 38.8327C258.705 37.8397 258.505 36.7772 258.517 35.7064Z"
          fill="#395056"
        />
        <path
          d="M277.412 37.538V27.7409H279.762V36.9701C279.762 39.8986 281.349 41.7302 284.128 41.7302C286.815 41.7302 288.86 39.7459 288.86 36.7855V27.7409H291.182V43.5228H288.86V40.7754C287.795 42.4828 286.173 43.8565 283.457 43.8565C279.641 43.8565 277.412 41.2936 277.412 37.538Z"
          fill="#395056"
        />
        <path
          d="M297.528 21.2378H299.882V43.5228H297.528V21.2378Z"
          fill="#395056"
        />
        <path
          d="M305.955 37.538V27.7409H308.305V36.9701C308.305 39.8986 309.892 41.7302 312.671 41.7302C315.359 41.7302 317.403 39.7459 317.403 36.7855V27.7409H319.721V43.5228H317.403V40.7754C316.338 42.4828 314.716 43.8565 312 43.8565C308.185 43.8565 305.955 41.2936 305.955 37.538Z"
          fill="#395056"
        />
        <path
          d="M325.862 27.7409H328.212V30.396C329.252 28.8377 330.654 27.4036 333.309 27.4036C334.371 27.3667 335.419 27.6459 336.322 28.2058C337.224 28.7658 337.94 29.5812 338.378 30.5487C339.507 28.8093 341.186 27.4036 343.934 27.4036C347.568 27.4036 349.794 29.8458 349.794 33.754V43.5228H347.444V34.2936C347.444 31.2409 345.918 29.5299 343.355 29.5299C340.973 29.5299 338.989 31.3048 338.989 34.4143V43.5122H336.667V34.2439C336.667 31.2799 335.113 29.5406 332.61 29.5406C330.108 29.5406 328.212 31.6171 328.212 34.5101V43.5157H325.862V27.7409Z"
          fill="#395056"
        />
        <path
          d="M65.4812 0.0851898H32.8134V21.6105H65.4812V0.0851898Z"
          fill="#00AFD7"
        />
        <path
          d="M65.4812 21.6105H32.8134V43.3276H65.4812V21.6105Z"
          fill="#007DB6"
        />
        <path
          d="M32.8134 0.0816498H22.1501H0.330109V65.0446H32.8134V43.3276V21.6106V0.0816498Z"
          fill="#00B5AF"
        />
      </g>
      <defs>
        <clipPath id="clip0_140_3339">
          <rect width="350" height="65.3144" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

function ProLogo() {
  return (
    <svg
      width="350"
      height="77"
      viewBox="0 0 350 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_140_3425)">
        <path
          d="M57.2343 0.0994222H38.2888V37.9947H57.2343C59.7461 38.0318 62.2403 37.5692 64.5716 36.6336C66.903 35.6981 69.0251 34.3083 70.8145 32.5452C72.6039 30.7821 74.0249 28.6808 74.9948 26.3635C75.9647 24.0462 76.4642 21.5591 76.4642 19.0471C76.4642 16.535 75.9647 14.048 74.9948 11.7307C74.0249 9.41336 72.6039 7.31204 70.8145 5.54891C69.0251 3.78577 66.903 2.39602 64.5716 1.46048C62.2403 0.524939 59.7461 0.0622835 57.2343 0.0994222Z"
          fill="#FFCE51"
        />
        <path
          d="M38.2887 0.0994263H0.385193V37.9947V75.8982H38.2887V37.9947V0.0994263Z"
          fill="#F16457"
        />
        <path
          d="M101.036 25.4402H110.343C115.956 25.4402 119.65 28.4225 119.65 33.3101V33.3805C119.65 38.7651 115.177 41.5154 109.883 41.5154H103.844V50.2882H101.036V25.4402ZM109.987 38.9722C114.108 38.9722 116.809 36.7728 116.809 33.5047V33.4343C116.809 29.8805 114.146 28.0497 110.132 28.0497H103.844V38.9888L109.987 38.9722Z"
          fill="#395056"
        />
        <path
          d="M125.76 25.4402H136.454C139.507 25.4402 141.959 26.3639 143.525 27.9255C144.789 29.2414 145.479 31.0053 145.443 32.8296V32.9C145.443 37.0213 142.601 39.4361 138.691 40.1444L146.329 50.3047H142.899L135.688 40.6456H128.568V50.3047H125.76V25.4402ZM136.206 38.1065C139.934 38.1065 142.601 36.1888 142.601 32.9911V32.9166C142.601 29.8639 140.257 28.0166 136.239 28.0166H128.568V38.1065H136.206Z"
          fill="#395056"
        />
        <path
          d="M151.337 37.945V37.8746C151.337 31.0154 156.489 25.0136 164.057 25.0136C171.624 25.0136 176.702 30.945 176.702 37.8V37.8746C176.702 44.7296 171.55 50.7148 163.986 50.7148C156.423 50.7148 151.337 44.8 151.337 37.945ZM173.791 37.945V37.8746C173.791 32.2249 169.669 27.6065 163.986 27.6065C158.304 27.6065 154.253 32.1627 154.253 37.8V37.8746C154.253 43.5201 158.374 48.1385 164.057 48.1385C169.74 48.1385 173.791 43.5905 173.791 37.945Z"
          fill="#395056"
        />
        <path
          d="M195.279 25.4402H198.088V47.7118H212.071V50.3047H195.279V25.4402Z"
          fill="#395056"
        />
        <path
          d="M216.312 41.1757V41.1053C216.312 35.8118 220.04 31.5497 225.122 31.5497C230.557 31.5497 233.684 35.8822 233.684 41.2461C233.691 41.5435 233.679 41.841 233.647 42.1367H219.083C219.473 46.113 222.281 48.3497 225.549 48.3497C228.072 48.3497 229.844 47.3183 231.348 45.7568L233.05 47.281C231.203 49.352 228.966 50.7272 225.483 50.7272C220.434 50.7313 216.312 46.8586 216.312 41.1757ZM230.913 40.1112C230.627 36.7728 228.709 33.8568 225.052 33.8568C221.854 33.8568 219.44 36.5242 219.083 40.1112H230.913Z"
          fill="#395056"
        />
        <path
          d="M238.087 44.9781V44.9077C238.087 41.0349 241.285 38.9722 245.957 38.9722C247.862 38.9601 249.758 39.2266 251.586 39.7633V39.1254C251.586 35.8118 249.56 34.1177 246.118 34.1177C244.181 34.1397 242.273 34.5999 240.539 35.4639L239.711 33.2272C241.791 32.2151 244.074 31.6923 246.388 31.6988C248.98 31.6988 250.973 32.3739 252.319 33.7242C253.562 34.9668 254.204 36.7438 254.204 39.0881V50.313H251.586V47.542C250.811 48.5572 249.806 49.3735 248.654 49.9238C247.502 50.474 246.235 50.7423 244.959 50.7065C241.533 50.6982 238.087 48.7432 238.087 44.9781ZM251.623 43.5574V41.7804C249.874 41.2652 248.061 41.0016 246.238 40.9976C242.792 40.9976 240.854 42.4929 240.854 44.8V44.8704C240.854 47.1816 242.987 48.5319 245.472 48.5319C248.852 48.5319 251.623 46.4692 251.623 43.5574Z"
          fill="#395056"
        />
        <path
          d="M261.038 31.939H263.772V36.7355C265.122 33.6828 267.785 31.4793 271.269 31.6201V34.5692H271.053C267.04 34.5692 263.772 37.4479 263.772 42.9899V50.3047H261.038V31.939Z"
          fill="#395056"
        />
        <path
          d="M276.417 31.939H279.172V35.1367C280.377 33.1485 282.295 31.5497 285.459 31.5497C289.899 31.5497 292.501 34.5319 292.501 38.9018V50.3047H289.763V39.5769C289.763 36.168 287.92 34.0349 284.685 34.0349C281.524 34.0349 279.18 36.3461 279.18 39.7923V50.3047H276.442L276.417 31.939Z"
          fill="#395056"
        />
        <path
          d="M299.368 24.9059H302.491V27.9255H299.368V24.9059ZM299.546 31.9474H302.28V50.313H299.546V31.9474Z"
          fill="#395056"
        />
        <path
          d="M309.632 31.939H312.37V35.1367C313.575 33.1485 315.493 31.5497 318.657 31.5497C323.098 31.5497 325.699 34.5319 325.699 38.9018V50.3047H322.953V39.5769C322.953 36.168 321.105 34.0349 317.875 34.0349C314.714 34.0349 312.37 36.3461 312.37 39.7923V50.3047H309.632V31.939Z"
          fill="#395056"
        />
        <path
          d="M332.297 53.5024L333.54 51.3734C335.58 52.9007 338.061 53.7234 340.61 53.7178C344.661 53.7178 347.287 51.4769 347.287 47.1817V45.0112C345.688 47.1444 343.451 48.884 340.076 48.884C335.673 48.884 331.444 45.5704 331.444 40.2894V40.2189C331.425 39.0798 331.634 37.9485 332.06 36.8917C332.486 35.8349 333.119 34.8741 333.922 34.066C334.725 33.2579 335.682 32.6189 336.736 32.1867C337.79 31.7545 338.92 31.5379 340.059 31.5497C343.505 31.5497 345.746 33.2562 347.237 35.2444V31.9308H350V47.0988C350 49.9444 349.147 52.1107 347.656 53.6018C346.024 55.2337 343.572 56.0539 340.66 56.0539C337.675 56.0908 334.753 55.1992 332.297 53.5024ZM347.362 40.2479V40.1775C347.362 36.4124 344.089 33.9645 340.61 33.9645C337.131 33.9645 334.26 36.3793 334.26 40.1444V40.2189C334.26 43.9136 337.209 46.4692 340.618 46.4692C344.089 46.4692 347.362 43.9467 347.362 40.2521V40.2479Z"
          fill="#395056"
        />
      </g>
      <defs>
        <clipPath id="clip0_140_3425">
          <rect width="350" height="76.213" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

function MastersLogo() {
  return (
    <svg
      width="350"
      height="68"
      viewBox="0 0 350 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_140_3489)">
        <path
          d="M90.0585 22.4619H92.5875L100.647 34.5384L108.707 22.4619H111.236V44.588H108.736V26.6671L100.677 38.5184H100.551L92.4915 26.704V44.5917H90.0585V22.4619Z"
          fill="#395056"
        />
        <path
          d="M116.733 39.8476V39.7848C116.733 36.3365 119.58 34.5053 123.718 34.5053C125.418 34.4898 127.111 34.7237 128.743 35.1994V34.6308C128.743 31.6772 126.941 30.1746 123.877 30.1746C122.153 30.1966 120.457 30.6094 118.915 31.3819L118.177 29.3919C120.028 28.4927 122.059 28.028 124.117 28.0332C126.425 28.0332 128.197 28.635 129.397 29.8349C130.504 30.9425 131.073 32.5227 131.073 34.6087V44.5955H128.732V42.1292C128.041 43.0334 127.146 43.7602 126.118 44.2494C125.091 44.7386 123.962 44.9762 122.825 44.9425C119.797 44.9351 116.733 43.1962 116.733 39.8476ZM128.776 38.5812V37.0011C127.218 36.5428 125.602 36.3091 123.977 36.307C120.912 36.307 119.207 37.6324 119.207 39.6888V39.7516C119.207 41.8043 121.101 43.0079 123.316 43.0079C126.31 43.0079 128.776 41.173 128.776 38.5812Z"
          fill="#395056"
        />
        <path
          d="M135.791 42.5021L137.024 40.7632C138.793 42.0886 140.753 42.8492 142.68 42.8492C144.608 42.8492 146.062 41.8375 146.062 40.2648V40.202C146.062 38.5591 144.135 37.9277 142.001 37.3259C139.443 36.5876 136.596 35.7126 136.596 32.711V32.6482C136.596 29.8349 138.937 27.9705 142.16 27.9705C144.249 28.009 146.286 28.6332 148.037 29.7721L146.93 31.6181C145.499 30.6493 143.824 30.1018 142.097 30.038C140.166 30.038 138.937 31.0496 138.937 32.4082V32.4747C138.937 34.0216 140.956 34.6234 143.138 35.2843C145.667 36.0448 148.384 37.0232 148.384 39.962V40.0285C148.384 43.1224 145.826 44.924 142.57 44.924C140.106 44.8806 137.725 44.0298 135.791 42.5021Z"
          fill="#395056"
        />
        <path
          d="M154.325 40.2427V30.3961H152.036V28.2474H154.325V23.3186H156.762V28.2474H161.93V30.3961H156.762V39.9399C156.762 41.9336 157.869 42.6609 159.508 42.6609C160.333 42.6653 161.147 42.4689 161.879 42.0886V44.1746C160.969 44.6519 159.953 44.8919 158.925 44.8724C156.304 44.8724 154.325 43.5654 154.325 40.2427Z"
          fill="#395056"
        />
        <path
          d="M165.626 36.4657V36.4029C165.626 31.692 168.949 27.9114 173.464 27.9114C178.301 27.9114 181.081 31.7658 181.081 36.5395C181.086 36.8033 181.076 37.0671 181.051 37.3296H168.092C168.439 40.8702 170.935 42.8676 173.844 42.8676C176.089 42.8676 177.669 41.952 179.013 40.5601L180.531 41.9188C178.888 43.7647 176.898 44.9868 173.8 44.9868C169.292 44.9683 165.626 41.52 165.626 36.4657ZM178.614 35.5169C178.363 32.5633 176.658 29.953 173.401 29.953C170.555 29.953 168.406 32.3233 168.092 35.5169H178.614Z"
          fill="#395056"
        />
        <path
          d="M186.231 28.2473H188.66V32.5152C189.86 29.7942 192.23 27.8375 195.328 27.963V30.5843H195.136C191.566 30.5843 188.66 33.1465 188.66 38.0753V44.588H186.224L186.231 28.2473Z"
          fill="#395056"
        />
        <path
          d="M199.917 28.5316C201.497 27.963 202.162 27.1397 202.036 25.781H200.896V22.4583H203.797V25.3048C203.797 27.8633 202.69 29.0964 200.29 29.6355L199.917 28.5316Z"
          fill="#395056"
        />
        <path
          d="M207.489 42.5021L208.723 40.7632C210.491 42.0886 212.451 42.8492 214.379 42.8492C216.306 42.8492 217.761 41.8375 217.761 40.2648V40.202C217.761 38.5591 215.833 37.9277 213.699 37.3259C211.137 36.5876 208.294 35.7126 208.294 32.711V32.6482C208.294 29.8349 210.635 27.9705 213.858 27.9705C215.947 28.009 217.984 28.6332 219.736 29.7721L218.628 31.6181C217.191 30.6362 215.505 30.0808 213.766 30.0158C211.835 30.0158 210.605 31.0274 210.605 32.3861V32.4525C210.605 33.9995 212.625 34.6013 214.807 35.2621C217.336 36.0227 220.053 37.001 220.053 39.9399V40.0063C220.053 43.1002 217.495 44.9019 214.238 44.9019C211.787 44.8598 209.417 44.017 207.489 42.5021Z"
          fill="#395056"
        />
        <path
          d="M236.232 22.4619H243.911C250.863 22.4619 255.67 27.2357 255.67 33.4603V33.5268C255.67 39.7515 250.87 44.6027 243.911 44.6027H236.232V22.4619ZM238.727 24.7694V42.2805H243.896C249.489 42.2805 253.063 38.4888 253.063 33.5896V33.5268C253.063 28.6275 249.489 24.7694 243.896 24.7694H238.727Z"
          fill="#395056"
        />
        <path
          d="M260.41 36.4657V36.4029C260.41 31.692 263.733 27.9114 268.248 27.9114C273.081 27.9114 275.865 31.7658 275.865 36.5395C275.871 36.8033 275.86 37.0673 275.832 37.3296H262.869C263.216 40.8702 265.716 42.8676 268.621 42.8676C270.866 42.8676 272.446 41.952 273.79 40.5601L275.307 41.9188C273.661 43.7647 271.671 44.9868 268.573 44.9868C264.076 44.9683 260.41 41.52 260.41 36.4657ZM273.399 35.5169C273.144 32.5633 271.438 29.953 268.182 29.953C265.339 29.953 263.19 32.3233 262.873 35.5169H273.399Z"
          fill="#395056"
        />
        <path
          d="M280.827 47.4309L281.935 45.5369C283.745 46.894 285.948 47.626 288.211 47.6228C291.814 47.6228 294.151 45.6292 294.151 41.8043V39.8734C292.73 41.771 290.74 43.318 287.735 43.318C283.817 43.318 280.055 40.3644 280.055 35.6719V35.6091C280.039 34.5945 280.225 33.5867 280.605 32.6455C280.984 31.7044 281.549 30.8489 282.265 30.1298C282.981 29.4107 283.834 28.8425 284.774 28.4589C285.713 28.0753 286.72 27.8841 287.735 27.8966C290.803 27.8966 292.793 29.414 294.122 31.1824V28.2289H296.555V41.723C296.555 44.2521 295.794 46.1793 294.469 47.5084C293.014 48.963 290.832 49.6903 288.24 49.6903C285.595 49.7216 283.005 48.9322 280.827 47.4309ZM294.225 35.6423V35.5796C294.225 32.231 291.32 30.0416 288.222 30.0416C285.124 30.0416 282.562 32.1903 282.562 35.539V35.6054C282.562 38.8913 285.187 41.1656 288.222 41.1656C291.32 41.1729 294.225 38.9282 294.225 35.6423Z"
          fill="#395056"
        />
        <path
          d="M302.887 28.2473H305.327V32.5152C306.527 29.7942 308.897 27.8375 311.995 27.963V30.5843H311.806C308.233 30.5843 305.327 33.1465 305.327 38.0753V44.588H302.894L302.887 28.2473Z"
          fill="#395056"
        />
        <path
          d="M314.863 36.4657V36.4029C314.863 31.692 318.186 27.9114 322.701 27.9114C327.538 27.9114 330.318 31.7658 330.318 36.5395C330.324 36.8033 330.314 37.0671 330.288 37.3296H317.33C317.677 40.8702 320.172 42.8676 323.082 42.8676C325.326 42.8676 326.907 41.952 328.232 40.5601L329.749 41.9188C328.107 43.7647 326.117 44.9868 323.019 44.9868C318.53 44.9683 314.863 41.52 314.863 36.4657ZM327.848 35.5169C327.593 32.5633 325.888 29.953 322.631 29.953C319.789 29.953 317.64 32.3233 317.322 35.5169H327.848Z"
          fill="#395056"
        />
        <path
          d="M334.46 36.4657V36.4029C334.46 31.692 337.783 27.9114 342.299 27.9114C347.131 27.9114 349.915 31.7658 349.915 36.5395C349.921 36.8033 349.91 37.0673 349.882 37.3296H336.923C337.274 40.8702 339.769 42.8676 342.675 42.8676C344.92 42.8676 346.5 41.952 347.844 40.5601L349.361 41.9188C347.718 43.7647 345.725 44.9868 342.627 44.9868C338.127 44.9683 334.46 41.52 334.46 36.4657ZM347.449 35.5169C347.198 32.5633 345.488 29.953 342.236 29.953C339.389 29.953 337.24 32.3233 336.923 35.5169H347.449Z"
          fill="#395056"
        />
        <path
          d="M0.343353 33.3312C0.343353 33.8235 0.354428 34.3034 0.37658 34.7711V31.8914C0.354428 32.3689 0.343353 32.8488 0.343353 33.3312Z"
          fill="#00AFD7"
        />
        <path
          d="M34.4942 0.088623H34.125V67.6519H34.4942C43.4536 67.6519 52.0461 64.0928 58.3814 57.7575C64.7167 51.4222 68.2758 42.8297 68.2758 33.8703C68.2758 24.9108 64.7167 16.3183 58.3814 9.98304C52.0461 3.64775 43.4536 0.088623 34.4942 0.088623Z"
          fill="#00AFD7"
        />
        <path
          d="M34.125 0.0849609H0.376587V3.27483V32.4267V35.3102V67.6519H32.5338H34.125V67.6446V0.0923437V0.0849609Z"
          fill="#007DB6"
        />
      </g>
      <defs>
        <clipPath id="clip0_140_3489">
          <rect width="350" height="67.9325" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

function GraduateLogo() {
  return (
    <svg
      width="350"
      height="63"
      viewBox="0 0 350 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_140_3553)">
        <path
          d="M83.212 31.6126V31.5546C83.212 25.9703 87.3055 21.0239 93.4254 21.0239C96.8572 21.0239 98.9824 22.0132 101.019 23.7291L99.5351 25.4757C97.8593 23.9084 95.63 23.0704 93.3367 23.1458C88.827 23.1458 85.5999 26.9596 85.5999 31.4966V31.5546C85.5999 36.4157 88.711 40.0215 93.6574 40.0215C95.7899 40.0128 97.8584 39.2924 99.5351 37.9747V32.9226H93.3367V30.8758H101.746V38.9913C99.4897 40.9964 96.5767 42.1045 93.5585 42.1058C87.1691 42.0887 83.212 37.4323 83.212 31.6126Z"
          fill="#395056"
        />
        <path
          d="M107.364 26.6969H109.602V30.6233C110.711 28.1228 112.89 26.3183 115.742 26.4342V28.8494H115.568C112.28 28.8494 109.602 31.2067 109.602 35.7471V41.7408H107.364V26.6969Z"
          fill="#395056"
        />
        <path
          d="M118.099 37.3743V37.3163C118.099 34.1438 120.719 32.4586 124.53 32.4586C126.094 32.441 127.653 32.6537 129.155 33.0897V32.5678C129.155 29.8626 127.498 28.4742 124.676 28.4742C123.09 28.4929 121.528 28.8708 120.109 29.5795L119.426 27.7476C121.126 26.9194 122.993 26.4912 124.885 26.4956C127.01 26.4956 128.637 27.0483 129.746 28.1535C130.769 29.1769 131.288 30.6267 131.288 32.5473V41.7578H129.132V39.4688C128.496 40.3021 127.671 40.9717 126.724 41.422C125.778 41.8724 124.738 42.0904 123.691 42.058C120.924 42.058 118.099 40.4581 118.099 37.3743ZM129.186 36.211V34.7578C127.746 34.3326 126.253 34.1167 124.751 34.1165C121.93 34.1165 120.358 35.3377 120.358 37.2276V37.289C120.358 39.1789 122.104 40.2841 124.141 40.2841C126.918 40.2841 129.186 38.5955 129.186 36.211Z"
          fill="#395056"
        />
        <path
          d="M136.022 34.2632V34.2018C136.022 29.2861 139.604 26.3762 143.21 26.3762C146.004 26.3762 147.808 27.8874 148.972 29.576V20.4985H151.213V41.7407H148.972V38.7115C147.75 40.5161 145.973 42.058 143.21 42.058C139.604 42.058 136.022 39.2061 136.022 34.2632ZM149.06 34.2325V34.1745C149.06 30.712 146.41 28.3752 143.602 28.3752C140.692 28.3752 138.305 30.5278 138.305 34.1745V34.2325C138.305 37.7836 140.75 40.0521 143.602 40.0521C146.41 40.0521 149.074 37.6949 149.074 34.2325H149.06Z"
          fill="#395056"
        />
        <path
          d="M156.801 36.0369V26.6968H159.039V35.4775C159.039 38.2713 160.553 40.0145 163.2 40.0145C165.762 40.0145 167.71 38.1247 167.71 35.3035V26.6968H169.921V41.7406H167.71V39.1208C166.687 40.7514 165.152 42.0579 162.559 42.0579C158.923 42.0579 156.801 39.6154 156.801 36.0369Z"
          fill="#395056"
        />
        <path
          d="M174.635 37.3743V37.3163C174.635 34.1438 177.255 32.4586 181.065 32.4586C182.63 32.441 184.188 32.6537 185.691 33.0897V32.5678C185.691 29.8626 184.033 28.4742 181.212 28.4742C179.626 28.4929 178.064 28.8708 176.644 29.5795L175.962 27.7476C177.662 26.9194 179.529 26.4912 181.42 26.4956C183.545 26.4956 185.173 27.0483 186.281 28.1535C187.305 29.1769 187.823 30.6267 187.823 32.5473V41.7578H185.667V39.4688C185.03 40.3045 184.202 40.9756 183.252 41.426C182.303 41.8764 181.259 42.0931 180.209 42.058C177.46 42.058 174.635 40.4581 174.635 37.3743ZM185.722 36.211V34.7578C184.282 34.3326 182.788 34.1167 181.287 34.1165C178.466 34.1165 176.897 35.3377 176.897 37.2276V37.289C176.897 39.1789 178.64 40.2841 180.676 40.2841C183.453 40.2841 185.722 38.5955 185.722 36.211Z"
          fill="#395056"
        />
        <path
          d="M194.073 37.753V28.6755H191.978V26.697H194.073V22.1736H196.314V26.714H201.09V28.6926H196.314V37.4801C196.314 39.312 197.337 39.9806 198.845 39.9806C199.604 39.9851 200.353 39.806 201.028 39.4587V41.3792C200.193 41.8139 199.262 42.0331 198.32 42.0171C195.904 42.0001 194.073 40.8095 194.073 37.753Z"
          fill="#395056"
        />
        <path
          d="M204.491 34.2632V34.2018C204.491 29.8694 207.544 26.3762 211.706 26.3762C216.14 26.3762 218.719 29.9274 218.719 34.3212C218.725 34.5638 218.715 34.8065 218.689 35.0478H206.749C207.07 38.3056 209.369 40.1374 212.043 40.1374C214.111 40.1374 215.564 39.2948 216.788 38.0156L218.184 39.2675C216.673 40.9527 214.837 42.0887 211.985 42.0887C207.865 42.0887 204.491 38.9162 204.491 34.2632ZM216.43 33.3899C216.198 30.6608 214.626 28.2729 211.631 28.2729C209.011 28.2729 207.032 30.4561 206.742 33.3899H216.43Z"
          fill="#395056"
        />
        <path
          d="M232.48 31.6126V31.5546C232.48 25.7929 236.786 21.0239 242.779 21.0239C246.477 21.0239 248.688 22.3305 250.724 24.251L249.152 25.926C247.446 24.2954 245.515 23.1321 242.752 23.1321C238.242 23.1321 234.865 26.7993 234.865 31.483V31.541C234.865 36.2554 238.276 39.9499 242.752 39.9499C245.546 39.9499 247.378 38.8753 249.329 37.0127L250.84 38.4966C248.715 40.6492 246.388 42.0751 242.694 42.0751C236.816 42.0887 232.48 37.463 232.48 31.6126Z"
          fill="#395056"
        />
        <path
          d="M254.187 34.2905V34.2325C254.183 33.2021 254.382 32.1811 254.773 31.2277C255.164 30.2744 255.738 29.4073 256.464 28.676C257.19 27.9448 258.053 27.3636 259.003 26.9658C259.954 26.568 260.973 26.3613 262.004 26.3575C264.085 26.3498 266.083 27.169 267.56 28.635C268.291 29.3609 268.873 30.2237 269.27 31.1742C269.668 32.1246 269.875 33.1441 269.879 34.1745V34.2325C269.884 35.2674 269.683 36.293 269.288 37.2496C268.893 38.2063 268.312 39.0749 267.579 39.8049C266.845 40.535 265.974 41.112 265.015 41.5023C264.057 41.8926 263.03 42.0884 261.995 42.0785C260.968 42.0922 259.948 41.9003 258.996 41.514C258.043 41.1277 257.178 40.5549 256.45 39.8292C255.723 39.1036 255.148 38.2397 254.759 37.2885C254.37 36.3373 254.176 35.318 254.187 34.2905ZM267.573 34.2905V34.2325C267.573 31.002 265.157 28.3548 261.985 28.3548C258.727 28.3548 256.486 31.002 256.486 34.1745V34.2325C256.486 37.463 258.874 40.0795 262.043 40.0795C265.301 40.0795 267.573 37.463 267.573 34.2905Z"
          fill="#395056"
        />
        <path
          d="M274.498 36.0369V26.6968H276.739V35.4775C276.739 38.2713 278.25 40.0145 280.897 40.0145C283.459 40.0145 285.407 38.1247 285.407 35.3035V26.6968H287.621V41.7406H285.407V39.1208C284.384 40.7514 282.848 42.0579 280.259 42.0579C276.619 42.0579 274.498 39.6154 274.498 36.0369Z"
          fill="#395056"
        />
        <path
          d="M293.468 26.6969H295.709V30.6233C296.814 28.1228 298.998 26.3183 301.849 26.4342V28.8494H301.672C298.387 28.8494 295.709 31.2067 295.709 35.7471V41.7408H293.468V26.6969Z"
          fill="#395056"
        />
        <path
          d="M304.817 39.8203L305.95 38.217C307.58 39.4416 309.385 40.1375 311.159 40.1375C312.933 40.1375 314.273 39.2063 314.273 37.7496V37.6916C314.273 36.177 312.496 35.5971 310.521 35.0445C308.164 34.3622 305.547 33.5605 305.547 30.794V30.736C305.547 28.1468 307.7 26.4309 310.664 26.4309C312.587 26.4673 314.461 27.0417 316.075 28.0888L315.051 29.7945C313.737 28.8957 312.197 28.3828 310.606 28.314C308.832 28.314 307.696 29.2452 307.696 30.4972V30.5552C307.696 31.9811 309.559 32.5337 311.565 33.1444C313.895 33.8437 316.395 34.7477 316.395 37.4528V37.5245C316.395 40.3763 314.038 42.0342 311.043 42.0342C308.781 41.9973 306.594 41.2196 304.817 39.8203Z"
          fill="#395056"
        />
        <path
          d="M320.441 34.2632V34.2018C320.441 29.8694 323.511 26.3762 327.656 26.3762C332.091 26.3762 334.67 29.9274 334.67 34.3212C334.675 34.5638 334.665 34.8065 334.639 35.0478H322.699C323.02 38.3056 325.319 40.1374 327.997 40.1374C330.044 40.1374 331.518 39.2948 332.739 38.0156L334.134 39.2675C332.623 40.9527 330.788 42.0887 327.936 42.0887C323.815 42.0887 320.441 38.9162 320.441 34.2632ZM332.401 33.3899C332.166 30.6608 330.596 28.2729 327.598 28.2729C324.981 28.2729 323.003 30.4561 322.71 33.3899H332.401Z"
          fill="#395056"
        />
        <path
          d="M338.159 39.8203L339.295 38.217C340.926 39.4416 342.731 40.1375 344.504 40.1375C346.278 40.1375 347.619 39.2063 347.619 37.7496V37.6916C347.619 36.177 345.842 35.5971 343.866 35.0445C341.509 34.3622 338.893 33.5605 338.893 30.794V30.736C338.893 28.1468 341.045 26.4309 344.01 26.4309C345.933 26.4673 347.807 27.0417 349.42 28.0888L348.397 29.7945C347.083 28.9059 345.547 28.4015 343.962 28.3378C342.185 28.3378 341.052 29.2691 341.052 30.5211V30.5791C341.052 32.005 342.915 32.5576 344.921 33.1682C347.25 33.8676 349.751 34.7716 349.751 37.4767V37.5245C349.751 40.3763 347.394 42.0342 344.399 42.0342C342.133 41.999 339.941 41.2212 338.159 39.8203Z"
          fill="#395056"
        />
        <path
          d="M31.5307 0.0817726C23.2524 0.0817726 15.3131 3.37032 9.45947 9.22398C3.60581 15.0776 0.317261 23.0169 0.317261 31.2952C0.317261 39.5735 3.60581 47.5128 9.45947 53.3665C15.3131 59.2201 23.2524 62.5087 31.5307 62.5087H62.9147V62.4268L31.5614 31.2509L62.9181 0.0749512H31.5341L31.5307 0.0817726Z"
          fill="#485DAA"
        />
        <path
          d="M62.9249 62.4336H62.9283V0.0817871L31.5716 31.2577L62.9249 62.4336Z"
          fill="#7275B7"
        />
      </g>
      <defs>
        <clipPath id="clip0_140_3553">
          <rect width="350" height="62.768" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

function NowLogo() {
  return (
    <svg
      width="350"
      height="65"
      viewBox="0 0 350 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M85.0353 21.896H87.235L100.353 38.5803V21.896H102.643V42.7147H100.768L87.3257 25.633V42.7147H85.0353V21.896Z"
        fill="#395056"
      />
      <path
        d="M108.441 32.3647V32.3055C108.441 26.5674 112.753 21.5405 119.091 21.5405C125.428 21.5405 129.681 26.4942 129.681 32.2462V32.3055C129.681 38.047 125.369 43.0739 119.035 43.0739C112.701 43.0739 108.441 38.1063 108.441 32.3647ZM127.238 32.3647V32.3055C127.238 27.5784 123.79 23.7123 119.031 23.7123C114.273 23.7123 110.881 27.5191 110.881 32.2462V32.3055C110.881 37.036 114.332 40.9021 119.091 40.9021C123.849 40.9021 127.241 37.0953 127.241 32.3647H127.238Z"
        fill="#395056"
      />
      <path
        d="M132.819 21.8957H135.374L141.321 39.2353L147.035 21.8364H148.966L154.676 39.2353L160.624 21.8957H163.092L155.642 42.8783H153.648L147.938 25.9709L142.196 42.8783H140.234L132.819 21.8957Z"
        fill="#395056"
      />
      <path
        d="M176.994 32.3647V32.3055C176.994 26.4175 181.397 21.5405 187.522 21.5405C191.301 21.5405 193.56 22.8792 195.641 24.8418L194.037 26.5849C192.294 24.9185 190.318 23.7298 187.494 23.7298C182.882 23.7298 179.434 27.4773 179.434 32.2636V32.3229C179.434 37.1406 182.92 40.9195 187.494 40.9195C190.349 40.9195 192.221 39.8179 194.215 37.9145L195.759 39.431C193.591 41.6342 191.228 43.0913 187.435 43.0913C181.425 43.0739 176.994 38.3468 176.994 32.3647Z"
        fill="#395056"
      />
      <path
        d="M199.183 35.101V35.0418C199.179 33.9888 199.382 32.9454 199.782 31.9712C200.181 30.9969 200.768 30.1108 201.51 29.3635C203.008 27.8543 205.045 27.0021 207.171 26.9942C209.298 26.9863 211.34 27.8235 212.849 29.3216C214.358 30.8197 215.211 32.856 215.219 34.9825V35.0418C215.223 36.0995 215.018 37.1477 214.614 38.1253C214.21 39.1029 213.616 39.9905 212.866 40.7365C212.116 41.4825 211.225 42.0721 210.246 42.4709C209.266 42.8697 208.217 43.0699 207.159 43.0597C206.109 43.0727 205.068 42.8759 204.095 42.4808C203.123 42.0857 202.239 41.5002 201.496 40.7588C200.753 40.0174 200.166 39.135 199.768 38.1634C199.371 37.1918 199.172 36.1506 199.183 35.101ZM212.862 35.101V35.0418C212.862 31.7405 210.394 29.0353 207.152 29.0353C203.819 29.0353 201.532 31.7405 201.532 34.9825V35.0418C201.532 38.3431 203.973 41.0203 207.211 41.0203C210.558 41.0203 212.862 38.3465 212.862 35.101Z"
        fill="#395056"
      />
      <path
        d="M220.179 27.3414H222.47V30.0152C223.481 28.3523 225.084 27.0137 227.734 27.0137C231.45 27.0137 233.622 29.5097 233.622 33.17V42.7149H231.331V33.7348C231.331 30.8797 229.783 29.0948 227.078 29.0948C224.432 29.0948 222.47 31.0261 222.47 33.9126V42.7149H220.179V27.3414Z"
        fill="#395056"
      />
      <path
        d="M240.189 29.3629H238.098V27.3688H240.189V26.0302C240.189 24.2872 240.667 22.9381 241.528 22.077C242.302 21.3031 243.431 20.8848 244.829 20.8848C245.688 20.8559 246.545 20.9871 247.357 21.2717V23.2657C246.637 23.0274 245.887 22.897 245.129 22.8788C243.344 22.8788 242.452 23.949 242.452 26.1487V27.4002H247.332V29.3629H242.483V42.7145H240.189V29.3629Z"
        fill="#395056"
      />
      <path
        d="M249.832 35.0734V35.0142C249.832 30.5834 252.969 27.0137 257.205 27.0137C261.757 27.0137 264.372 30.6427 264.372 35.1327C264.377 35.3805 264.368 35.6285 264.344 35.8752H252.143C252.471 39.2079 254.82 41.0799 257.557 41.0799C259.648 41.0799 261.154 40.2188 262.402 38.9081L263.832 40.1875C262.284 41.9131 260.412 43.0739 257.498 43.0739C253.279 43.0739 249.832 39.8319 249.832 35.0734ZM262.054 34.181C261.817 31.3922 260.21 28.9519 257.145 28.9519C254.468 28.9519 252.446 31.183 252.15 34.181H262.054Z"
        fill="#395056"
      />
      <path
        d="M269.221 27.3412H271.511V31.3537C272.641 28.7984 274.872 26.9543 277.786 27.0728V29.5409H277.609C274.248 29.5409 271.511 31.9498 271.511 36.5897V42.7147H269.221V27.3412Z"
        fill="#395056"
      />
      <path
        d="M280.495 35.0734V35.0142C280.495 30.5834 283.632 27.0137 287.868 27.0137C292.421 27.0137 295.035 30.6427 295.035 35.1327C295.041 35.3805 295.031 35.6285 295.007 35.8752H282.806C283.134 39.2079 285.484 41.0799 288.22 41.0799C290.329 41.0799 291.818 40.2188 293.066 38.9081L294.495 40.1875C292.947 41.9131 291.075 43.0739 288.161 43.0739C283.943 43.0739 280.495 39.8319 280.495 35.0734ZM292.717 34.181C292.48 31.3922 290.873 28.9519 287.809 28.9519C285.135 28.9519 283.11 31.183 282.813 34.181H292.717Z"
        fill="#395056"
      />
      <path
        d="M299.884 27.3414H302.175V30.0152C303.186 28.3523 304.793 27.0137 307.439 27.0137C311.155 27.0137 313.327 29.5097 313.327 33.17V42.7149H311.036V33.7348C311.036 30.8797 309.492 29.0948 306.783 29.0948C304.137 29.0948 302.175 31.0261 302.175 33.9126V42.7149H299.884V27.3414Z"
        fill="#395056"
      />
      <path
        d="M318.085 35.1011V35.0419C318.073 33.997 318.268 32.9601 318.658 31.9908C319.048 31.0215 319.626 30.1389 320.359 29.3938C321.091 28.6486 321.964 28.0557 322.926 27.649C323.889 27.2422 324.922 27.0298 325.967 27.0239C328.881 27.0239 330.698 28.244 332.124 29.7605L330.607 31.3641C329.387 30.0847 328.02 29.0458 325.939 29.0458C322.802 29.0458 320.435 31.6918 320.435 34.993V35.0523C320.435 38.385 322.875 41.0309 326.086 41.0309C328.048 41.0309 329.572 40.0478 330.785 38.7406L332.242 40.1106C330.698 41.8327 328.854 43.0842 325.967 43.0842C324.924 43.0824 323.892 42.874 322.93 42.4709C321.967 42.0678 321.095 41.4781 320.362 40.7358C319.629 39.9936 319.05 39.1134 318.66 38.1463C318.269 37.1791 318.074 36.1442 318.085 35.1011Z"
        fill="#395056"
      />
      <path
        d="M335.456 35.0734V35.0142C335.456 30.5834 338.594 27.0137 342.829 27.0137C347.382 27.0137 349.997 30.6427 349.997 35.1327C350.002 35.3805 349.992 35.6285 349.969 35.8752H337.767C338.095 39.2079 340.445 41.0799 343.181 41.0799C345.294 41.0799 346.779 40.2188 348.027 38.9081L349.456 40.1875C347.908 41.9131 346.036 43.0739 343.122 43.0739C338.904 43.0739 335.456 39.8319 335.456 35.0734ZM347.678 34.181C347.441 31.3922 345.834 28.9519 342.77 28.9519C340.096 28.9519 338.071 31.183 337.774 34.181H347.678Z"
        fill="#395056"
      />
      <path
        d="M32.2077 0.0800781L32.2251 32.2424L40.4557 40.1837L64.3073 63.8784V0.0800781H32.2077Z"
        fill="#C4D82E"
      />
      <path
        d="M32.2251 32.0088L0.324188 0.0834961V63.8539L32.2251 63.8783V32.0088Z"
        fill="#D666A7"
      />
      <path
        d="M32.2251 32.0088V63.8783H64.3073L40.4557 40.1836L32.2251 32.0088Z"
        fill="#F58021"
      />
    </svg>
  )
}
