import { useTranslations } from "next-intl"
import PropTypes from "prop-types"
import { useState, forwardRef } from "react"
import { styled } from "@/styles/stitches.config"
import { LegacyTextInput } from "~/components/legacy-text-input"
import { SvgIcon } from "~/components/svg-icons"
import { isNotEmpty } from "~/util"

const _PasswordInput = styled("div", {
  position: "relative",
  button: {
    "--size": "calc(2.375rem - 2px)",
    "alignItems": "center",
    "background": "transparent",
    "border": "transparent",
    "cursor": "pointer",
    "display": "flex",
    "height": "var(--size)",
    "justifyContent": "center",
    "padding": "0",
    "position": "absolute",
    "right": "calc(0.25rem - 3px)",
    "top": "calc(1.5rem + 1px)",
    "width": "var(--size)",
    "&:hover,&:focus": {
      borderRadius: "0.1875rem",
      boxShadow: "0 0 0 0.0625rem $gray300",
    },
    "svg": {
      "--size": "1.25rem",
      "color": "$gray500",
      "height": "var(--size)",
      "width": "var(--size)",
    },
  },
})

export const PasswordInput = forwardRef((props, ref) => {
  const { reveal, className, label, disabled, error, ...other } = props

  const t = useTranslations("components.passwordInput")
  const [revealPassword, setRevealPassword] = useState(reveal)
  const inputType = revealPassword ? "text" : "password"
  const toggleIconSymbolId = revealPassword ? "eye-slash" : "eye"

  const togglePassword = () => setRevealPassword(!revealPassword)

  return (
    <>
      <_PasswordInput>
        <LegacyTextInput
          ref={ref}
          type={inputType}
          inputId="password"
          className={className}
          label={isNotEmpty(label) ? label : t("defaultLabel")}
          required={true}
          disabled={disabled}
          error={error}
          {...other}
        />
        <button
          type="button"
          aria-label={
            revealPassword
              ? t("hidePasswordToggleAriaLabel")
              : t("revealPasswordToggleAriaLabel")
          }
          title={
            revealPassword
              ? t("hidePasswordToggleAriaLabel")
              : t("revealPasswordToggleAriaLabel")
          }
          onClick={togglePassword}>
          <SvgIcon symbolId={toggleIconSymbolId} />
        </button>
      </_PasswordInput>
    </>
  )
})

PasswordInput.displayName = "PasswordInput"
PasswordInput.propTypes = {
  reveal: PropTypes.bool.isRequired,
  className: PropTypes.string,
  inputId: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object,
}

PasswordInput.defaultProps = {
  reveal: false,
}
