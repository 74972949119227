export function AOEULogo(): JSX.Element {
  return (
    <svg viewBox="0 0 200 45" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.29 16.0707C35.6446 6.74758 27.047 -0.101588 16.825 0.00114033C14.6317 0.0231849 12.2911 0.680746 10.9628 2.30434C10.2693 3.15189 10.0803 4.43443 10.5518 5.51477C10.6995 5.85319 11.2838 6.86316 12.6129 7.05578C13.1341 7.13133 14.0191 7.0301 14.918 7.10714C16.591 7.25053 17.7432 8.00606 18.1798 9.41866C18.6764 11.0252 17.6148 12.8602 15.1492 13.7078C13.6088 14.2373 11.4195 14.6304 8.13758 16.2761C1.49198 19.6086 -0.935106 26.8898 0.316965 32.4182C1.40611 37.2272 4.83544 41.2759 9.35112 42.9934C15.2262 45.2278 22.965 44.768 29.198 40.6241C33.8917 37.5035 36.1932 33.3856 37.0315 31.5321C39.343 26.4211 39.7307 21.1481 38.29 16.0707ZM16.0872 35.0976C13.0424 35.9796 9.85917 34.2262 8.97724 31.1814C8.09526 28.1367 9.84857 24.9535 12.8933 24.0716C15.9381 23.1897 19.1214 24.9431 20.0033 27.9878C20.8853 31.0326 19.132 34.2158 16.0872 35.0976ZM23.4963 8.80224C22.3473 8.80224 21.4159 7.87083 21.4159 6.72188C21.4159 5.57294 22.3473 4.64152 23.4963 4.64152C24.6452 4.64152 25.5766 5.57294 25.5766 6.72188C25.5766 7.87083 24.6452 8.80224 23.4963 8.80224ZM26.9574 13.1536C26.9574 11.7176 28.1215 10.5534 29.5576 10.5534C30.9936 10.5534 32.1578 11.7176 32.1578 13.1536C32.1578 14.5897 30.9936 15.7538 29.5576 15.7538C28.1215 15.7538 26.9574 14.5897 26.9574 13.1536ZM30.174 26.391C28.3951 26.391 26.9531 24.949 26.9531 23.1702C26.9531 21.3913 28.3951 19.9493 30.174 19.9493C31.9528 19.9493 33.3948 21.3913 33.3948 23.1702C33.3948 24.949 31.9528 26.391 30.174 26.391Z"
        fill="currentColor"
      />
      <path
        d="M53.7744 18.3946C53.7744 19.209 54.2269 19.5064 54.8992 19.5064C55.2352 19.5064 55.5197 19.4417 55.8686 19.2737V20.1269C55.5197 20.3079 55.1448 20.4112 54.6664 20.4112C53.5934 20.4112 52.779 19.8813 52.779 18.524V14.4905H51.8481V13.6114H52.779V11.5948H53.7744V13.6114H55.8945V14.4905H53.7744V18.3946Z"
        fill="currentColor"
      />
      <path
        d="M59.9409 20.2949H58.9456V10.858H59.9409V14.775C60.3805 14.051 61.0784 13.4693 62.2291 13.4693C63.845 13.4693 64.7886 14.5552 64.7886 16.1452V20.2949H63.7932V16.3909C63.7932 15.1499 63.1209 14.3743 61.9446 14.3743C60.7942 14.3743 59.9409 15.2146 59.9409 16.4683V20.2949Z"
        fill="currentColor"
      />
      <path
        d="M70.8906 19.5838C71.8086 19.5838 72.455 19.209 72.9978 18.6402L73.6183 19.196C72.946 19.946 72.1316 20.45 70.865 20.45C69.0291 20.45 67.5297 19.041 67.5297 16.9596C67.5297 15.0207 68.8869 13.4693 70.7355 13.4693C72.7136 13.4693 73.8511 15.0463 73.8511 17.0114C73.8511 17.1018 73.8511 17.2053 73.8381 17.3216H68.538C68.6801 18.7694 69.7143 19.5838 70.8906 19.5838ZM72.8427 16.5848C72.7392 15.3696 72.0413 14.3096 70.7097 14.3096C69.5463 14.3096 68.6672 15.2791 68.538 16.5848H72.8427Z"
        fill="currentColor"
      />
      <path
        d="M82.4481 14.8913L82.1507 14.0769C82.8877 13.7408 83.6115 13.521 84.581 13.521C86.4039 13.521 87.4252 14.4517 87.4252 16.2099V20.2949H86.4684V19.2866C86.0032 19.8942 85.2274 20.4371 84.0511 20.4371C82.81 20.4371 81.5561 19.7391 81.5561 18.3428C81.5561 16.9208 82.7194 16.1711 84.413 16.1711C85.2662 16.1711 85.8738 16.2874 86.4684 16.4556V16.2229C86.4684 15.0207 85.7316 14.3999 84.4777 14.3999C83.6891 14.3999 83.0686 14.607 82.4481 14.8913ZM82.5644 18.3042C82.5644 19.1574 83.3399 19.6485 84.245 19.6485C85.473 19.6485 86.4814 18.8988 86.4814 17.8388V17.1924C85.9902 17.0502 85.3309 16.9079 84.5165 16.9079C83.2625 16.9079 82.5644 17.451 82.5644 18.3042Z"
        fill="currentColor"
      />
      <path
        d="M91.5751 20.2949H90.5797V13.6114H91.5751V15.3567C92.0662 14.2449 93.0359 13.4434 94.3028 13.4952V14.5682H94.2252C92.7644 14.5682 91.5751 15.6153 91.5751 17.6319V20.2949Z"
        fill="currentColor"
      />
      <path
        d="M98.4528 18.3946C98.4528 19.209 98.9053 19.5064 99.5775 19.5064C99.9136 19.5064 100.198 19.4417 100.547 19.2737V20.1269C100.198 20.3079 99.8232 20.4112 99.3448 20.4112C98.2718 20.4112 97.4574 19.8813 97.4574 18.524V14.4905H96.5265V13.6114H97.4574V11.5948H98.4528V13.6114H100.573V14.4905H98.4528V18.3946Z"
        fill="currentColor"
      />
      <path
        d="M111.846 20.45C109.842 20.45 108.381 18.873 108.381 16.9726C108.381 15.0722 109.855 13.4693 111.872 13.4693C113.875 13.4693 115.349 15.0463 115.349 16.9467C115.349 18.8471 113.862 20.45 111.846 20.45ZM111.846 14.3484C110.398 14.3484 109.402 15.4988 109.402 16.9467C109.402 18.3946 110.463 19.5582 111.872 19.5582C113.32 19.5582 114.328 18.4075 114.328 16.9726C114.328 15.5118 113.255 14.3484 111.846 14.3484Z"
        fill="currentColor"
      />
      <path
        d="M121.658 13.6373V14.4905H119.551V20.2949H118.555V14.4905H117.638V13.6243H118.555V13.0427C118.555 11.5819 119.292 10.8063 120.572 10.8063C121.037 10.8063 121.348 10.858 121.671 10.9743V11.8405C121.309 11.7369 121.037 11.6724 120.701 11.6724C119.926 11.6724 119.538 12.1377 119.538 13.0944V13.6373H121.658Z"
        fill="currentColor"
      />
      <path
        d="M132.336 19.5838C133.254 19.5838 133.9 19.209 134.443 18.6402L135.064 19.196C134.391 19.946 133.577 20.45 132.31 20.45C130.474 20.45 128.975 19.041 128.975 16.9596C128.975 15.0207 130.333 13.4693 132.181 13.4693C134.159 13.4693 135.297 15.0463 135.297 17.0114C135.297 17.1018 135.297 17.2053 135.284 17.3216H129.983C130.126 18.7694 131.16 19.5838 132.336 19.5838ZM134.288 16.5848C134.185 15.3696 133.487 14.3096 132.155 14.3096C130.992 14.3096 130.113 15.2791 129.983 16.5848H134.288Z"
        fill="currentColor"
      />
      <path
        d="M143.7 20.2949V18.9504C143.157 19.7521 142.368 20.4371 141.14 20.4371C139.537 20.4371 137.947 19.1702 137.947 16.9596C137.947 14.7621 139.537 13.4693 141.14 13.4693C142.381 13.4693 143.182 14.1416 143.7 14.8913V10.858H144.695V20.2949H143.7ZM141.321 14.3613C140.028 14.3613 138.968 15.3179 138.968 16.9467C138.968 18.5367 140.054 19.5452 141.321 19.5452C142.562 19.5452 143.738 18.511 143.738 16.9467C143.738 15.3955 142.562 14.3613 141.321 14.3613Z"
        fill="currentColor"
      />
      <path
        d="M152.697 13.6114H153.68V20.2949H152.697V19.1316C152.245 19.8554 151.559 20.4371 150.409 20.4371C148.793 20.4371 147.85 19.3513 147.85 17.7611V13.6114H148.845V17.5154C148.845 18.7565 149.517 19.5323 150.693 19.5323C151.831 19.5323 152.697 18.692 152.697 17.438V13.6114Z"
        fill="currentColor"
      />
      <path
        d="M159.963 20.45C158.011 20.45 156.537 18.86 156.537 16.9726C156.537 15.0722 158.011 13.4693 159.963 13.4693C161.23 13.4693 162.018 13.9992 162.638 14.6585L161.979 15.3567C161.45 14.8009 160.855 14.3484 159.95 14.3484C158.592 14.3484 157.558 15.4988 157.558 16.9467C157.558 18.4075 158.618 19.5582 160.015 19.5582C160.867 19.5582 161.527 19.1316 162.057 18.5626L162.69 19.1574C162.018 19.9071 161.217 20.45 159.963 20.45Z"
        fill="currentColor"
      />
      <path
        d="M165.754 14.8913L165.457 14.0769C166.194 13.7408 166.918 13.521 167.888 13.521C169.71 13.521 170.732 14.4517 170.732 16.2099V20.2949H169.775V19.2866C169.31 19.8942 168.534 20.4371 167.357 20.4371C166.117 20.4371 164.863 19.7391 164.863 18.3428C164.863 16.9208 166.026 16.1711 167.72 16.1711C168.572 16.1711 169.18 16.2874 169.775 16.4556V16.2229C169.775 15.0207 169.038 14.3999 167.784 14.3999C166.996 14.3999 166.375 14.607 165.754 14.8913ZM165.871 18.3042C165.871 19.1574 166.646 19.6485 167.552 19.6485C168.78 19.6485 169.788 18.8988 169.788 17.8388V17.1924C169.297 17.0502 168.637 16.9079 167.823 16.9079C166.569 16.9079 165.871 17.451 165.871 18.3042Z"
        fill="currentColor"
      />
      <path
        d="M175.425 18.3946C175.425 19.209 175.877 19.5064 176.549 19.5064C176.885 19.5064 177.17 19.4417 177.519 19.2737V20.1269C177.17 20.3079 176.795 20.4112 176.316 20.4112C175.243 20.4112 174.429 19.8813 174.429 18.524V14.4905H173.498V13.6114H174.429V11.5948H175.425V13.6114H177.544V14.4905H175.425V18.3946Z"
        fill="currentColor"
      />
      <path
        d="M180.621 12.1506V11.0519H181.759V12.1506H180.621ZM180.686 20.2949V13.6114H181.681V20.2949H180.686Z"
        fill="currentColor"
      />
      <path
        d="M188.081 20.45C186.077 20.45 184.616 18.873 184.616 16.9726C184.616 15.0722 186.09 13.4693 188.106 13.4693C190.11 13.4693 191.584 15.0463 191.584 16.9467C191.584 18.8471 190.097 20.45 188.081 20.45ZM188.081 14.3484C186.633 14.3484 185.637 15.4988 185.637 16.9467C185.637 18.3946 186.697 19.5582 188.106 19.5582C189.554 19.5582 190.563 18.4075 190.563 16.9726C190.563 15.5118 189.49 14.3484 188.081 14.3484Z"
        fill="currentColor"
      />
      <path
        d="M195.152 20.2949H194.157V13.6114H195.152V14.775C195.592 14.051 196.29 13.4693 197.44 13.4693C199.056 13.4693 200 14.5552 200 16.1452V20.2949H199.005V16.3909C199.005 15.1499 198.332 14.3743 197.156 14.3743C196.005 14.3743 195.152 15.2146 195.152 16.4683V20.2949Z"
        fill="currentColor"
      />
      <path
        d="M52.757 31.3386V26.1352H53.767V31.2746C53.767 33.2054 54.7896 34.292 56.5029 34.292C58.1522 34.292 59.2007 33.2948 59.2007 31.3386V26.1352H60.211V31.2619C60.211 33.8702 58.7148 35.2256 56.4775 35.2256C54.2656 35.2256 52.757 33.8702 52.757 31.3386Z"
        fill="currentColor"
      />
      <path
        d="M70.211 26.1352H71.1573L76.7957 33.3075V26.1352H77.7802V35.0848H76.9747L71.1955 27.7458V35.0848H70.211V26.1352Z"
        fill="currentColor"
      />
      <path
        d="M88.0109 26.1352H89.0207V35.0848H88.0109V26.1352Z"
        fill="currentColor"
      />
      <path
        d="M98.4831 26.1352H99.6083L102.792 33.8447L105.988 26.1352H107.075L103.227 35.1489H102.332L98.4831 26.1352Z"
        fill="currentColor"
      />
      <path
        d="M116.449 26.1352H122.919V27.0556H117.46V30.1112H122.343V31.0316H117.46V34.1644H122.983V35.0848H116.449V26.1352Z"
        fill="currentColor"
      />
      <path
        d="M132.625 26.1352H136.473C137.573 26.1352 138.455 26.4676 139.017 27.0302C139.452 27.4648 139.708 28.0914 139.708 28.7943V28.8198C139.708 30.3033 138.685 31.1724 137.279 31.4281L140.028 35.0848H138.788L136.192 31.6074H133.635V35.0848H132.625V26.1352ZM136.384 30.6992C137.726 30.6992 138.685 30.009 138.685 28.8584V28.8329C138.685 27.7331 137.841 27.0683 136.396 27.0683H133.635V30.6992H136.384Z"
        fill="currentColor"
      />
      <path
        d="M148.89 33.7807L149.516 33.0392C150.45 33.8829 151.345 34.3047 152.585 34.3047C153.787 34.3047 154.58 33.6658 154.58 32.7835V32.7581C154.58 31.9267 154.132 31.4539 152.253 31.0575C150.194 30.6098 149.248 29.945 149.248 28.4747V28.4492C149.248 27.0429 150.488 26.0071 152.189 26.0071C153.493 26.0071 154.426 26.3781 155.334 27.1065L154.746 27.8866C153.915 27.2091 153.084 26.9149 152.163 26.9149C151 26.9149 150.258 27.5542 150.258 28.3598V28.3852C150.258 29.2289 150.718 29.7021 152.687 30.1239C154.682 30.5589 155.603 31.2877 155.603 32.6555V32.6813C155.603 34.2153 154.324 35.2129 152.547 35.2129C151.128 35.2129 149.964 34.7397 148.89 33.7807Z"
        fill="currentColor"
      />
      <path
        d="M165.36 26.1352H166.37V35.0848H165.36V26.1352Z"
        fill="currentColor"
      />
      <path
        d="M178.927 27.0683H175.909V26.1352H182.954V27.0683H179.937V35.0848H178.927V27.0683Z"
        fill="currentColor"
      />
      <path
        d="M195.205 31.5434L191.484 26.1352H192.711L195.729 30.6098L198.772 26.1352H199.948L196.227 31.5307V35.0848H195.205V31.5434Z"
        fill="currentColor"
      />
    </svg>
  )
}
