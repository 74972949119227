import clsx from "clsx"
import { nanoid } from "nanoid"
import PropTypes from "prop-types"
import { forwardRef, useState, useEffect, useRef } from "react"
import { styled } from "@/styles/stitches.config"

export const InnerCheckbox = styled("label", {
  "&.checkbox-label": {
    alignItems: "center",
    color: "$oracle",
    cursor: "pointer",
    display: "grid",
    gridTemplateColumns: "min-content auto",
    gridGap: "0.5rem",
  },
  ".checkbox--input": {
    "alignItems": "center",
    "display": "grid",
    "gridTemplateAreas": '"checkbox"',
    "justifyItems": "center",
    "input": {
      "--size": "1rem",
      "height": "var(--size)",
      "opacity": 0,
      "width": "var(--size)",
    },
    "input:checked + .checkbox--control": {
      boxShadow: "0 0 0 0.1rem $white, 0 0 0.025rem 0.2rem $allPorts",
      svg: {
        transform: "scale(1)",
      },
    },
    "input[disabled] + .checkbox--control": {
      svg: {
        color: "$gray300",
      },
    },
    "*": {
      gridArea: "checkbox",
    },
  },
  ".checkbox--control": {
    "--size": "1.25rem",
    "alignItems": "center",
    "border": "0.1rem solid $gray400",
    "borderRadius": "0.25rem",
    "display": "inline-grid",
    "height": "var(--size)",
    "justifyItems": "center",
    "overflow": "hidden",
    "width": "var(--size)",
    "svg": {
      "--size": "90%",
      "color": "$aoeuBlue",
      "height": "var(--size)",
      "width": "var(--size)",
      "transform": "scale(0)",
      "transformOrigin": "center",
      "transition": "transform 100ms ease-in-out 100ms",
    },
  },
  ".checkbox-label--text": {
    fontSize: "1rem",
    fontWeight: 500,
  },
})

// Implementation and styling adapted from:
// https://moderncss.dev/pure-css-custom-checkbox-style/
export const Checkbox = forwardRef((props, ref) => {
  const uid = useRef(nanoid())

  const {
    inputId,
    name,
    value,
    checked,
    className,
    label,
    labelClass,
    size,
    onChange,
    disabled,
  } = props

  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const handleCheckChanged = e => {
    if (e && e.target) {
      const {
        target: { value: checkboxValue, checked: checkboxIsChecked },
      } = e

      setIsChecked(checkboxIsChecked)

      if (onChange && typeof onChange === "function") {
        onChange({
          value: checkboxValue,
          checked: checkboxIsChecked,
          // otherwise we get this cryptic "target is undefined"
          target: e.target,
        })
      }
    }
  }

  const checkboxProps = {
    "type": "checkbox",
    "id": inputId,
    "checked": isChecked,
    "aria-label": label,
    name,
    value,
    ref,
  }

  return (
    <InnerCheckbox
      id={`label-${uid.current}`}
      className={clsx(["checkbox-label", className])}
      data-testid={`${inputId}-checkbox-label`}>
      <span className="checkbox--input">
        <input
          {...checkboxProps}
          onChange={e => handleCheckChanged(e)}
          disabled={disabled}
          data-testid={`${inputId}-checkbox-input`}
        />
        <span
          className="checkbox--control"
          role="checkbox"
          aria-label={label}
          aria-labelledby={`label-${uid.current}`}
          aria-checked={isChecked}
          style={{
            height: size,
            width: size,
          }}>
          {/* eslint-disable max-len */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            aria-hidden="true"
            focusable="false">
            <path
              fill="currentColor"
              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
            />
          </svg>
          {/* eslint-enable max-len */}
        </span>
      </span>
      <span
        className={clsx(["checkbox-label--text", labelClass])}
        style={{
          lineHeight: size,
        }}>
        {label}
      </span>
    </InnerCheckbox>
  )
})

Checkbox.displayName = "Checkbox"
Checkbox.propTypes = {
  inputId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  labelClass: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
}

Checkbox.defaultProps = {
  checked: false,
  size: "1.25rem",
  disabled: false,
}
